import React, { useEffect, useState } from 'react'
import { ADD_ICON, EDIT_ICON, DELETE_ICON, CROSS_BTN, MODAL_CROSS, PHONEICONE, WHATSAPPICON, USER_PROFILE, SEARCH_ICON } from "../../../Middleware/images";
import "../../../assets/style/customer-leads.css"
import "./attendence.css"
import { useNavigate } from 'react-router-dom';
import Modals from '../../../components/modal';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ReactLoader from '../../../Loader/react-loader';
import ROUTES from '../../../Middleware/routes';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
import Pagination from '../../../components/pagination';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import ERR_MESSAGE from '../../../Helper/error-helper';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
    // height: 650,
    overflow: "hidden",
    overflowY: "scroll"
};

const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

function Attendence() {
    const [deltId, setDeltId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [total, setTotal] = useState("")
    const [total1, setTotal1] = useState("")
    let message = localStorage.getItem("message")
    const role_id = localStorage.getItem("role_id")
    const [search, setSearch] = useState("")
    const [filterItems, setFilterItems] = useState([])
    const [staffList, setStaffList] = useState([]);
    const [staffDetail, setStaffDetail] = useState([]);
    const [errors, setErrors] = useState('');
    const [specializationArray, setSpecializationArray] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1);
    const endIndex = search == "" ? Math.min(startIndex + pageSize - 1, total) : Math.min(startIndex + pageSize - 1, total1)
    const totalPages = search == "" ? Math.ceil(total / pageSize) : Math.ceil(total1 / pageSize);
    const [fields, setFeilds] = useState({
        shift: "",
        startTime: "",
        endTime: ""
    })
    const [checkIn,setCheckIn] = useState('')
    const handleChange = (e) => {
        setErrors('')
        const name = e.target.name
        const value = e.target.value
        setFeilds({
            ...fields,
            [name]: value
        })
    }

    async function handleSearch(value) {
        let result = await Services.getService(`admin/search?staff=${value}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setFilterItems(result?.body)
            setTotal1(result?.body?.length)
        }
        else {
        }
    }

    useEffect(() => {
        if (message) {
            toast.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        };
    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getApi(page);
    };

    const handleDeleteOpen = (id) => {
        setDeltId(id);
        setOpenDelete(true);
    };

    const handleDeleteClose = () => setOpenDelete(false);
    const handleOpen = (id) => {
        setOpen(true);
        getStaffApi(id)
    };

    const handleClose = () => setOpen(false);

    async function getApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-list?offset=${pageNumber}&limit=${10}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setStaffList(result?.body?.data);
            setTotal(result?.body?.total)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }


    async function getStaffApi(id) {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setStaffDetail(result?.body);
            if (Array.isArray(result?.body?.specialization)) {
                setSpecializationArray(result?.body?.specialization)
            }
            else {
                setSpecializationArray(result?.body?.specialization?.split(','))
            }
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }


    async function getDeleteAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/delete-trainer?id=${deltId}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleDeleteClose()
            toast.success(result.message)
            getApi(currentPage)
        } else {
            toast.error(result.message)
            setLoading(false);
        }
    }
    const handleEditFunction = (id) => {
        navigate(ROUTES.EDIT_STAFF, { state: { id } })
    }
    const handleEdit = (id) => {
        navigate(ROUTES.EDIT_STAFF, { state: { id } })
    }

    useEffect(() => {
        getApi(currentPage)
    }, [])

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
      
        if (!checkIn) {
            formIsValid = false;
            errors["checkIn"] = ERR_MESSAGE.CHECK_IN;
        }
      
        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {

        }
    }

    return (
        <>
            <Helmet>
                <title>{TITLES.STAFF}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <Modals />
            <section className="main-spacing mt-3 main-height">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="row pb-2">
                                <div className="col-12 col-md-6">
                                    <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0">
                                        <div className="leads-head">
                                            <h3>Attendence</h3>
                                        </div>
                                      
                                    </div>
                                </div>

                            </div>
                           

                        </div>
                    </div>
                </div>

                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="modal-head staff-modal-head">
                        <div
                            onClick={handleClose}
                            className=" position-relative mb-0 text-end close-modal-btn"
                            style={{ cursor: "pointer" }}
                        >
                            <img
                                src={MODAL_CROSS} className="img-fluid" alt='' />
                        </div>
                        <div className="modal-staff-header position-relative text-center">

                            <h2 className="customer-modal-text fw-600">Add Time
                            </h2>
                        </div>
                        <form className='create-field' onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="col-md-12">
                                   
                                    <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Check In</label>

                                        <input name='checkIn'
                                            value={checkIn} onChange={(e)=>setCheckIn(e.target.value)}
                                            type="time" className="form-control common-input time-input0" placeholder='Select Time'
                                        />
                                        {errors.checkIn && <span className="err_msg">{errors.checkIn}</span>}
                                    </div>
                                </div>
                               
                                <div className="col-12 d-flex justify-content-end align-self-end py-3 mb-1 px-3 text-center position-relative " >
                                    <button type="submit" className="btn delete-btn  w-100">
                                        submit{" "}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </section>
            <ToastContainer />
        </>
    )
}
export default Attendence;