import React, { useEffect, useState } from 'react'
import { ADD_ICON, EDIT_ICON, DELETE_ICON, CROSS_BTN, MODAL_CROSS, PHONEICONE, WHATSAPPICON, USER_PROFILE, SEARCH_ICON } from "../../../Middleware/images";
import "../../../assets/style/customer-leads.css"
import { useNavigate } from 'react-router-dom';
import Modals from '../../../components/modal';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ReactLoader from '../../../Loader/react-loader';
import ROUTES from '../../../Middleware/routes';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
import Pagination from '../../../components/pagination';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
    // height: 650,
    overflow: "hidden",
    overflowY: "scroll"
};

const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

function Staff() {
    const [deltId, setDeltId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [total, setTotal] = useState("")
    const [total1, setTotal1] = useState("")
    let message = localStorage.getItem("message")
    const role_id = localStorage.getItem("role_id")
    const [search, setSearch] = useState("")
    const [filterItems, setFilterItems] = useState([])
    const [staffList, setStaffList] = useState([]);
    const [staffDetail, setStaffDetail] = useState([]);
    const [specializationArray, setSpecializationArray] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 7;
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1);
    const endIndex = search == "" ? Math.min(startIndex + pageSize - 1, total) : Math.min(startIndex + pageSize - 1, total1)
    const totalPages = search == "" ? Math.ceil(total / pageSize) : Math.ceil(total1 / pageSize);

    async function handleSearch(value) {
        let result = await Services.getService(`admin/search?staff=${value}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setFilterItems(result?.body)
            setTotal1(result?.body?.length)
        }
        else {
        }
    }
    useEffect(() => {
        if (message) {
            toast.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        };
    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getApi(page);
    };

    const handleDeleteOpen = (id) => {
        setDeltId(id);
        setOpenDelete(true);
    };

    const handleDeleteClose = () => setOpenDelete(false);
    const handleOpen = (id) => {
        setOpen(true);
        getStaffApi(id)
    };

    const handleClose = () => setOpen(false);

    async function getApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-list?offset=${pageNumber}&limit=${7}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setStaffList(result?.body?.data);
            setTotal(result?.body?.total)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    async function getStaffApi(id) {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setStaffDetail(result?.body);
            if (Array.isArray(result?.body?.specialization)) {
                setSpecializationArray(result?.body?.specialization)
            }
            else {
                setSpecializationArray(result?.body?.specialization?.split(','))
            }
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    async function getDeleteAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/delete-trainer?id=${deltId}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleDeleteClose()
            toast.success(result.message)
            getApi(currentPage)
        } else {
            toast.error(result.message)
            setLoading(false);
        }
    }
    const handleEditFunction = (id) => {
        navigate(ROUTES.EDIT_STAFF, { state: { id } })
    }
    const handleEdit = (id) => {
        navigate(ROUTES.EDIT_STAFF, { state: { id } })
    }

    useEffect(() => {
        getApi(currentPage)
    }, [])

    return (
        <>
            <Helmet>
                <title>{TITLES.STAFF}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <Modals />
            <section className="main-spacing mt-3 main-height">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 leads-table">
                            <div className="row pb-2">
                                <div className="col-12 col-md-6">
                                    <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0">
                                        <div className="leads-head">
                                            <h3>Staff</h3>
                                        </div>
                                        <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3">
                                            {/* <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">All Customers</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div> */}

                                            {/* <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div> */}
                                            <div className="search-category position-relative ">
                                                <form className="d-flex" >
                                                    <input className="form-control ms-0 ms-sm-2 nav-input-search cus-nav-input-search" type="search" placeholder="Search staff..."
                                                        value={search}
                                                        onChange={(e) => {
                                                            setSearch(e.target.value)
                                                            handleSearch(e.target.value)
                                                        }}
                                                    />
                                                    <img src={SEARCH_ICON} className="search-icon custom-search-icon" alt='' />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="add-label text-end d-flex align-items-center justify-content-start justify-content-sm-end">
                                        <button type="button" className="btn add-leads-btn d-flex align-items-center" onClick={() => navigate(ROUTES.ADD_STAFF)}><img src={ADD_ICON} className="img-fluid me-2" alt='' />Add New Staff</button>
                                    </div>
                                </div>
                            </div>
                            {
                                search?.length == 0 ? <>
                                    <div className="table-responsive leads-table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="leads-table-head">Name</th>
                                                    <th scope="col" className="leads-table-head">Join Date</th>
                                                    <th scope="col" className="leads-table-head">ROLE</th>
                                                    <th scope="col" className="leads-table-head">Experiece</th>
                                                    <th scope="col" className="leads-table-head">BRANCH</th>
                                                    <th scope="col" className="leads-table-head">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {staffList?.map((items) =>
                                                    <tr key={items?.id}>
                                                        <th scope="row" className="leads-data-head" style={{ width: "200px" }}>{items?.name}</th>
                                                        <td className="leads-table-data"> {moment(items?.created_at).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{items?.role_id == 1 ? "Admin" : items?.role_id == 2 ? "Manager" : items?.role_id == 3 ? "Receptionist" : "Trainer"}</td>
                                                        <td className="leads-table-data">{items?.experience ? items?.experience : "-"}<p></p></td>
                                                        <td className="leads-table-data">{items?.branch_id == 1 ? "Mohali,Punjab" : items?.branch_id === 2 ? "Kharar,Punjab" : items?.branch_id === 3 ? "Panchkula,Punjab" : "Chandigarh,Punjab"}</td>
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center gap-3">
                                                                <span className="upgrade-text" style={{ cursor: "pointer" }} onClick={() => handleOpen(items?.id)} >View</span>
                                                                {
                                                                     role_id == "3" ? "" : <img src={DELETE_ICON} style={{ cursor: "pointer" }} alt="" className="img-fluid" onClick={() => handleDeleteOpen(items?.id)} />
                                                                 }

                                                                <img src={EDIT_ICON} style={{ cursor: "pointer" }} onClick={() => handleEdit(items?.id)} alt="" className="img-fluid" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        staffList?.length > 0 && <>

                                            <div className="row pt-3">
                                                <div className="col-6">
                                                    <a href=""><span className="page-entry">
                                                        {
                                                            total === 1 ? <>
                                                                Showing {total} entry

                                                            </> :
                                                                <>
                                                                    Showing {startIndex} to {endIndex} of {total} entries

                                                                </>
                                                        }
                                                    </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <nav aria-label="Page">
                                                        <ul className="pagination d-flex justify-content-end">
                                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </> : <>
                                    <div className="table-responsive leads-table-responsive">
                                        <table className="table">
                                            {
                                                filterItems?.length == 0 ? "" : <thead>
                                                    <tr>
                                                        <th scope="col" className="leads-table-head">Name</th>
                                                        <th scope="col" className="leads-table-head">Join Date</th>
                                                        <th scope="col" className="leads-table-head">ROLE</th>
                                                        <th scope="col" className="leads-table-head">Experiece</th>
                                                        <th scope="col" className="leads-table-head">BRANCH</th>
                                                        <th scope="col" className="leads-table-head">Action</th>
                                                    </tr>
                                                </thead>
                                            }

                                            <tbody>
                                                {filterItems?.length === 0 ? <div className='notFound'>No staff found</div> : filterItems?.map((items) =>
                                                    <tr key={items?.id}>
                                                        <th scope="row" className="leads-data-head" style={{ width: "200px" }}>{items?.name}</th>
                                                        <td className="leads-table-data"> {moment(items?.created_at).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{items?.role_id == 1 ? "Admin" : items?.role_id == 2 ? "Manager" : items?.role_id == 3 ? "Receptionist" : "Trainer"}</td>
                                                        <td className="leads-table-data">{items?.experience ? items?.experience : "-"}<p></p></td>
                                                        <td className="leads-table-data">{items?.branch_id == 1 ? "Mohali,Punjab" : items?.branch_id === 2 ? "Kharar,Punjab" : items?.branch_id === 3 ? "Panchkula,Punjab" : "Chandigarh,Punjab"}</td>
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center gap-3">
                                                                <span className="upgrade-text" style={{ cursor: "pointer" }} onClick={() => handleOpen(items?.id)} >View</span>
                                                                {
                                                                    role_id == "3" ? "" : <img src={DELETE_ICON} style={{ cursor: "pointer" }} alt="" className="img-fluid" onClick={() => handleDeleteOpen(items?.id)} />
                                                                }

                                                                <img src={EDIT_ICON} style={{ cursor: "pointer" }} onClick={() => handleEdit(items?.id)} alt="" className="img-fluid" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        filterItems?.length > 0 && <>

                                            <div className="row pt-3">
                                                <div className="col-6">
                                                    <a href=""><span className="page-entry">
                                                        {
                                                            total1 === 1 ? <>
                                                                Showing {total1} entry

                                                            </> :
                                                                <>
                                                                    Showing {startIndex} to {endIndex} of {total1} entries

                                                                </>
                                                        }
                                                    </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <nav aria-label="Page">
                                                        <ul className="pagination d-flex justify-content-end">
                                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </>
                                    }

                                </>
                            }

                        </div>
                    </div>
                </div>
                <Modal
                    open={openDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={boxStyle} className="modal-head">
                        <div
                            onClick={handleDeleteClose}
                            className=" position-relative mb-0 text-end pt-1"
                            style={{ cursor: "pointer" }}
                        >
                            <img src={CROSS_BTN} alt=''  />
                        </div>
                        <div className="signin-head text-center pb-3">
                            <h2>Delete Staff</h2>
                        </div>
                        <div className="mb-4 position-relative">
                            <p>
                            Are you sure you want to delete the staff?

                            </p>
                        </div>
                        <div
                            className="mb-3 text-center position-relative submit-head"
                            onClick={getDeleteAPI}
                        >
                            <button
                                type="submit"
                                className="btn delete-btn"
                            >
                                Delete{" "}
                            </button>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="modal-head staff-modal-head">
                        <div
                            onClick={handleClose}
                            className=" position-relative mb-0 text-end close-modal-btn"
                            style={{ cursor: "pointer" }}
                        >
                            <img
                                src={MODAL_CROSS} className="img-fluid" alt=''  />
                        </div>
                        <div className="modal-staff-header position-relative text-center">

                            <h2 className="customer-modal-text fw-600">Staff Detail
                            </h2>
                        </div>
                        <div className={staffDetail?.profile_pic == "" ? "user-profile text-center my-2 user-profile-before" : "user-profile text-center my-2"}>
                            <img src={staffDetail?.profile_pic ? staffDetail?.profile_pic : USER_PROFILE} alt="" className={staffDetail?.profile_pic == "" ? "staff-profile-after" : "staff-profile-before"} />
                        </div>
                        <h2 className="user-name text-center fw-600">{staffDetail?.name}
                        </h2>
                        <div className="col-12 text-center mx-auto my-2">
                            <ul className="staff-modal-li d-flex justify-content-center  responsive-staff-li my-1">
                                <li>
                                    <p className="fw-400 f18-size text-grey">{staffDetail.role_id == 1 ? "Admin" : staffDetail?.role_id == 2 ? "Manager" : "Trainer"}</p>
                                </li>
                                <li>
                                    <p className="fw-400 f18-size text-grey">{staffDetail?.gender == 1 ? "Male" : staffDetail.gender == 2 ? "Female" : "Others"}</p>
                                </li>
                                <li>
                                    <p className="fw-400 f18-size text-grey">{staffDetail?.experience}</p>
                                </li>
                            </ul>

                        </div>
                        <div className="col-12 mx-auto text-center my-2">
                            <p className="email-text fw-400 f18-size text-grey">{staffDetail?.email}</p>
                        </div>

                        <div className="col-12 mx-auto">
                            <ul className="staff-modal-li d-flex justify-content-center gap-0  my-1">
                                <li className="contact-li">
                                    <h6 className="d-flex align-items-center gap-1 justify-content-end staff-list-mobile"><img
                                        src={PHONEICONE} alt="" /> Mobile No.</h6>
                                    <p className="fw-400 f-16 text-grey d-flex align-items-center gap-1 justify-content-end pe-3">{staffDetail?.mobile_number}</p>
                                </li>
                                <li className="contact-li">
                                    <h6 className="d-flex align-items-center gap-1"><img
                                        src={WHATSAPPICON} alt='' /> Whatsapp No.</h6>
                                    <p className="fw-400 f-16 text-grey d-flex align-items-center gap-1 justify-content-start ps-2">{staffDetail?.whatsapp_no}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="staff-list">
                            <div className="address-detail px-3">
                                <div className='border-bottom  mb-1'>
                                    <div className='row'>
                                        <div className="col-md-6  col-6 py-2">
                                            <h6 className="fw-500 f16-size text-start text-black">Branch</h6>
                                        </div>
                                        <div className="col-md-6 text-end  col-6 py-2" >
                                            <p className="fw-400 f16-size text-grey text-end">{staffDetail?.branch_id == 1 ? "Panchkula" : staffDetail?.branch_id === 2 ? "Kharar" : staffDetail?.branch_id === 3 ? "hajhsjf" : "xyz"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='border-bottom mb-1'>
                                    <div className='row'>
                                        <div className="col-md-6  col-6 py-2">
                                            <h6 className="fw-500 f16-size text-start text-black">Address</h6>
                                        </div>
                                        <div className="col-md-6 text-end py-2   col-6">
                                            <p className="fw-400 f16-size text-grey text-end">{staffDetail?.address_1}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    staffDetail?.about && <div className="col-md-12 border-bottom py-2 mb-1 ">
                                        <h6 className="fw-500 f16-size mb-2 text-start text-black">About</h6>
                                        <p className="fw-400 f16-size text-grey my-1 text-start trainerAbout">{staffDetail?.about}</p>
                                    </div>
                                }
                                {
                                    specializationArray[0] === "" ? "" : <div className="col-md-12 py-2 text-start mb-1">
                                        <h6 className="fw-500 f16-size mt-1 mb-2 text-black">Specializations</h6>
                                        <div className="d-flex align-items-center flex-wrap my-2 gap-2">
                                            {
                                                specializationArray?.map((item) => (
                                                    <span className="specializations-span " >{item}
                                                    </span>
                                                ))
                                            }

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end align-self-end py-3 mb-1 px-3 text-center position-relative  " onClick={() => handleEditFunction(staffDetail.id)}>
                            <button type="submit" className="btn delete-btn  w-100">
                                Edit{" "}
                            </button>
                        </div>
                    </Box>
                </Modal>
            </section>
            <ToastContainer />
        </>
    )
}
export default Staff;