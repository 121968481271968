import React, { useState } from 'react'
import { welcome_to_fit_x_pro, login_to_your_account, login_copyright } from './../../Helper/words';
import { LOGIN_LOGO, EYE_ICON, EYE_CLOSE } from '../../Middleware/images'
import "../../assets/style/login.css"
import { useNavigate } from 'react-router-dom';
import ERR_MESSAGE from '../../Helper/error-helper';
import Services from './../../Helper/services';
import ReactLoader from '../../Loader/react-loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ROUTES from '../../Middleware/routes';
import TITLES from '../../Helper/title';
import { Helmet } from 'react-helmet';

const Login = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState("");
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        email: "",
        password: ""
    })

    const [isVisible, setIsVisible] = useState(false);

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setFields({
            ...fields, [name]: value
        })
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const { email, password } = fields
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
        if (!password || password.trim().length === 0) {
            formIsValid = false;
            errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    }

    async function Submit(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "email": fields.email,
                "password": fields.password,
            });
            setLoading(true);
            const LoginResponse = await Services.postService("auth/login", data)
            if (LoginResponse.code == 200) {
                localStorage.setItem("access_token", LoginResponse.body.token);
                localStorage.setItem("refresh_token", LoginResponse.body.refresh_token);
                localStorage.setItem("company_id", LoginResponse?.body?.user?.company_id);
                localStorage.setItem("branch_id", LoginResponse?.body?.user?.branch_id);
                localStorage.setItem("role_id", LoginResponse?.body?.user?.role_id);

                toast.success(LoginResponse.message);
                setLoading(false);
                navigate(ROUTES.CUSTOMERS);
            } else {
                setLoading(false);
                toast.error(LoginResponse.message);
            }
        }
    }
    return (
        <div>
            <ToastContainer />
            <Helmet>
                <title>{TITLES.LOGIN}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <section className="login-head">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="login-form-head px-4 px-sm-5 mx-auto">
                                <div className="form-logo text-center">
                                    <img src={LOGIN_LOGO} alt="" className="login-logo" />
                                </div>
                                <div className="form-text py-3 py-md-4">
                                    <h1 className="form-head-text pb-3">{welcome_to_fit_x_pro}</h1>
                                    <p className="Login-text">{login_to_your_account}</p>
                                </div>
                                <form onSubmit={Submit}>
                                    <div className="form-group mb-3">
                                        <label className="form-label input-label">Email</label>
                                        <input type="text" className="form-control input-field" name="email" value={fields.email} onChange={handleChange} error={errors.email} placeholder="Enter your email" />
                                        {errors.email && <span className="err_msg">{errors.email}</span>}
                                    </div>
                                    <div className="form-group mb-3 position-relative">
                                        <label className="form-label input-label">Password</label>
                                        <input type={isVisible ? 'text' : 'password'} className="form-control input-field password-field" name="password" value={fields.password} onChange={handleChange} error={errors.password} placeholder="Enter your Password" />
                                        <img src={isVisible ? EYE_ICON : EYE_CLOSE} alt="eye-icon" className="img-fluid eye-icon" onClick={() => setIsVisible(!isVisible)} />
                                        {errors.password && <span className="err_msg">{errors.password}</span>}

                                    </div>
                                    <div className="form-group sign-in-head">
                                        <button type="submit" className="Sign-btn">Sign In</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="footer-head text-center">
                        <p className="footer-text">{login_copyright}</p>
                    </div>
                </footer>
            </section>

        </div>
    )
}

export default Login
