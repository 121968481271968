import React from 'react'
import { CALENDER_STAFF, CLOCK_STAFF, PHONEICONE, USER_PROFILE, WHATSAPPICON } from '../../../Middleware/images'

function AttendenceDetail() {
  return (
    <section className="main-spacing mt-3 main-height">

      <div className="row pb-2">
        <div className="col-12 col-md-6">
          <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0">
            <div className="leads-head">
              <h3>Attendence/Attendence History</h3>
            </div>
            <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3">
              {/* <div className="search-category position-relative ">
                    <form className="d-flex" >
                      <input className="form-control ms-0 ms-sm-2 nav-input-search cus-nav-input-search" type="search" placeholder="Search ..."
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value)
                          handleSearch(e.target.value)
                        }}
                      />
                      <img src={SEARCH_ICON} className="search-icon custom-search-icon" alt='' />
                    </form>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className='col-lg-3'>
          <div className=' leads-table'>
            <div className={"user-profile text-center my-2 user-profile-before"}>
              {/* <div className={staffDetail?.profile_pic == "" ? "user-profile text-center my-2 user-profile-before" : "user-profile text-center my-2"}> */}
              <img src={USER_PROFILE} alt="" className={"staff-profile-after"} />
              {/* <img src={staffDetail?.profile_pic ? staffDetail?.profile_pic : USER_PROFILE} alt="" className={staffDetail?.profile_pic == "" ? "staff-profile-after" : "staff-profile-before"} /> */}
            </div>
            <h2 className="user-name text-center fw-600">John Smith
              {/* <h2 className="user-name text-center fw-600">{staffDetail?.name} */}
            </h2>
            <div className="col-12 text-center mx-auto my-2">
              <ul className="staff-modal-li d-flex justify-content-center  responsive-staff-li my-1">
                <li>
                  <p className="fw-400 f18-size text-grey"> Trainer</p>
                  {/* <p className="fw-400 f18-size text-grey">{staffDetail.role_id == 1 ? "Admin" : staffDetail?.role_id == 2 ? "Manager" : "Trainer"}</p> */}
                </li>
                <li>
                  <p className="fw-400 f18-size text-grey">Male</p>
                  {/* <p className="fw-400 f18-size text-grey">{staffDetail?.gender == 1 ? "Male" : staffDetail.gender == 2 ? "Female" : "Others"}</p> */}
                </li>
                <li>
                  {/* <p className="fw-400 f18-size text-grey">{staffDetail?.experience}</p> */}
                  <p className="fw-400 f18-size text-grey">6 Years</p>
                </li>
              </ul>

            </div>
            <div className="col-12 mx-auto text-center my-2">
              {/* <p className="email-text fw-400 f18-size text-grey">{staffDetail?.email}</p> */}
              <p className="email-text fw-400 f18-size text-grey">dummymail1542@gmail.com</p>
            </div>
            <div className="col-12 mx-auto mb-3">
              <ul className="staff-modal-li d-flex justify-content-center gap-0  my-1">
                <li className="contact-li">
                  <h6 className="d-flex align-items-center gap-1"><img
                    src={WHATSAPPICON} alt='' /> Whatsapp No.</h6>
                  <p className="fw-400 f-16 text-grey d-flex align-items-center gap-1 justify-content-start ps-2">+12589654858</p>
                  {/* <p className="fw-400 f-16 text-grey d-flex align-items-center gap-1 justify-content-start ps-2">{staffDetail?.whatsapp_no}</p> */}
                </li>
                <li className="contact-li">
                  <h6 className="d-flex align-items-center gap-1 justify-content-end staff-list-mobile px-0"><img
                    src={PHONEICONE} alt="" /> Mobile No.</h6>
                  <p className="fw-400 f-16 text-grey d-flex align-items-center gap-1 justify-content-end px-0">+12589654858</p>
                  {/* <p className="fw-400 f-16 text-grey d-flex align-items-center gap-1 justify-content-end pe-3">{staffDetail?.mobile_number}</p> */}
                </li> </ul>
            </div>
            <div className='row mb-3'>
              <div className='col-sm-6 '>
                <div className='totaolattend d-flex align-items-center gap-2'>
                  <img src={CALENDER_STAFF} className=''></img>
                  <div className=''>
                    <p className='text-grey f14 fw-500'>200</p>
                    <p className=' f14 fw-500 text-blackish'>Total  Attendance</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 mt-3 mt-sm-0'>
                <div className='totaolattend d-flex align-items-center gap-2'>
                  <img src={CLOCK_STAFF} className=''></img>
                  <div className=''>
                    <p className='text-grey f14 fw-500'>08 hour 12 min</p>
                    <p className=' f14 fw-500 text-blackish'>Average daily time</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="address-detail px-3">
                <div className='border-bottom  mb-1'>
                  <div className='row'>
                    <div className="col-md-6  col-6 py-2">
                      <h6 className="fw-500 f16-size text-start text-black">Branch</h6>
                    </div>
                    <div className="col-md-6 text-end  col-6 py-2" >
                      <p className="fw-400 f16-size text-grey text-end">10 phase</p>
                      {/* <p className="fw-400 f16-size text-grey text-end">{staffDetail?.branch_id == 1 ? "Panchkula" : staffDetail?.branch_id === 2 ? "Kharar" : staffDetail?.branch_id === 3 ? "hajhsjf" : "xyz"}</p> */}
                    </div>
                  </div>
                </div>
                <div className='border-bottom mb-1'>
                  <div className='row'>
                    <div className="col-md-6  col-6 py-2">
                      <h6 className="fw-500 f16-size text-start text-black">Address</h6>
                    </div>
                    <div className="col-md-6 text-end py-2   col-6">
                      <p className="fw-400 f16-size text-grey text-end">#20 New Housing Board Sector 65 S.A.S Nagar. </p>
                      {/* <p className="fw-400 f16-size text-grey text-end">{staffDetail?.address_1}</p> */}
                    </div>
                  </div>
                </div>
                {/* {
                  staffDetail?.about && */}
                <div className="col-md-12 border-bottom py-2 mb-1 ">
                  <h6 className="fw-500 f16-size mb-2 text-start text-black">About Trainer</h6>
                  <p className="fw-400 f16-size text-grey my-1 text-start trainerAbout">Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                  {/* <p className="fw-400 f16-size text-grey my-1 text-start trainerAbout">{staffDetail?.about}</p> */}
                </div>
                {/* } */}
                {/* {
                  specializationArray[0] === "" ? "" :  */}
                <div className="col-md-12 py-2 text-start mb-1">
                  <h6 className="fw-500 f16-size mt-1 mb-2 text-black">Specializations</h6>
                  <div className="d-flex align-items-center flex-wrap my-2 gap-2">
                    {/* {
                        specializationArray?.map((item) => (
                          <span className="specializations-span " >{item}
                          </span>
                        ))
                      } */}
                    <span className="specializations-span " >WERTYUIOP
                    </span>
                    <span className="specializations-span " >GGGGG
                    </span>
                    <span className="specializations-span " >DFGHJKL
                    </span>

                  </div>
                </div>
                {/* } */}

              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-9 '>
          <div className='leads-table h-100'>
            <div className="leads-head mb-4">
              <h3>Attendance History</h3>
            </div>
            <div className='row'>
              <div className='col-sm-6 col-lg-4 col-xxl-3 mb-3'>
                <div className=" totaolattend py-4">
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className="date-container d-flex align-items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                        <path d="M11.9 2.60098H11.2V1.20098C11.2 1.01533 11.1263 0.837277 10.995 0.706002C10.8637 0.574726 10.6857 0.500977 10.5 0.500977C10.3143 0.500977 10.1363 0.574726 10.005 0.706002C9.87375 0.837277 9.8 1.01533 9.8 1.20098V2.60098H4.2V1.20098C4.2 1.01533 4.12625 0.837277 3.99497 0.706002C3.8637 0.574726 3.68565 0.500977 3.5 0.500977C3.31435 0.500977 3.1363 0.574726 3.00503 0.706002C2.87375 0.837277 2.8 1.01533 2.8 1.20098V2.60098H2.1C1.54305 2.60098 1.0089 2.82223 0.615076 3.21605C0.221249 3.60988 0 4.14402 0 4.70098V5.40098H14V4.70098C14 4.14402 13.7788 3.60988 13.3849 3.21605C12.9911 2.82223 12.457 2.60098 11.9 2.60098ZM0 12.401C0 12.9579 0.221249 13.4921 0.615076 13.8859C1.0089 14.2797 1.54305 14.501 2.1 14.501H11.9C12.457 14.501 12.9911 14.2797 13.3849 13.8859C13.7788 13.4921 14 12.9579 14 12.401V6.80098H0V12.401Z" fill="#EB4D2A" />
                      </svg>
                      <span className="fw-600 f14 text-black">July 15, 2024</span>
                    </div>
                    <p className="orange-btn ">Full Day</p>
                    {/* <p className="green-btn orange-btn ">Custom</p>
                  <p className="blue-btn orange-btn ">Half Day</p> */}
                  </div>
                  <div className="time-container d-flex justify-content-between pt-4">
                    <div className="time">
                      <p className="text-grey">Start Time</p>
                      <p className="fw-600 text-blackish">08:00 am</p>
                    </div>
                    <div className="time">
                      <p className="text-grey">End Time</p>
                      <p className="fw-600 text-blackish">04:00 pm</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default AttendenceDetail