import React, { useState, useRef, useEffect } from 'react'
import { ADD_BTN_ICON, CROSS_BTN, BULK_UPLOAD } from "../Middleware/images"
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    height: 550,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    overflow: "hidden",
    overflowY: "scroll"
};

function Consent(props) {
    const [open, setOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const modalRef = useRef();

    const handleOpen = (url) => {
        setSelectedImageUrl(url);

        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (
        <>
            <div className="row h-100">
                <div className="col-12 col-sm-7 m-auto">
                    <div className="video-upload video-select position-relative">
                        {props?.selectedFile?.length === 0 ?
                            <div>
                                <input type="file" className="video-file" onChange={props?.fileChange} />
                                <div className="upload-img d-flex m-auto justify-content-center align-items-center">
                                    <div>
                                        <img src={BULK_UPLOAD} className="img-fluid" />
                                        <p className="Upload-text fw-600 text-center py-3">Upload Consent Form</p>
                                        <p className="Upload-text-content fw-600 text-center pb-3">Please Upload jpeg,png,jpg</p>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="preview-head ">
                                <div className="preview-content d-flex gap-4">
                                    <div className="preview-docs position-relative text-center">
                                        <input type="file" onChange={props?.fileChange} className="video-file add-video-file" />
                                        <p className="upload-add-btn"><img src={ADD_BTN_ICON} className="mb-1" /> Add</p>
                                    </div>
                                    {props?.previewUrl?.map((url, index) =>
                                        <div className="preview-docs position-relative text-center">
                                            <img src={url} alt="" className="img-fluid preview-docs-img" />
                                            <img src={CROSS_BTN} key={index} onClick={() => props?.handleDelete(index)} className="preview-docs-cross" />
                                            <button
                                                type="button"
                                                className="preview-docs-btn"
                                                onClick={() => handleOpen(url)}
                                            >
                                                View
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <Box
                        ref={modalRef}
                        sx={style}
                        className="modal-head"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            onClick={handleClose}
                            className=" position-relative mb-0 text-end pt-1"
                            style={{ cursor: "pointer" }}
                        >
                            <img src={CROSS_BTN} alt="Close" />
                        </div>
                        <div>
                            <img src={selectedImageUrl} className='img-fluid' alt="Preview" />
                        </div>
                    </Box>
                </div>
            </Modal>
        </>
    )
}

export default Consent