import './loader.css';
function ReactLoader() {
  return (
    <div className="loader-new">
      <div className="custom-loading-color" style={{ position: "absolute", top: "350px", left: "700px", color: "#ed6214" }}>
        {/* <span className="k-loading-text" >Loading</span> */}
        <div className="k-loading-image" ></div>
        <div className="k-loading-color" ></div>
      </div>
    </div>
  )
}
export default ReactLoader;