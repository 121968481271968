import React, { useState } from 'react'
import "../../../assets/style/addWorkout.css"
import uploadFileToS3 from '../../../Helper/fileUpload';
import ERR_MESSAGE from '../../../Helper/error-helper';
import { ADD, ADDBRANCH, REMOVE } from '../../../Middleware/images';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Middleware/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoader from '../../../Loader/react-loader';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';

const EditWorkout = () => {
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});
    const [imageUrl, setImageUrl] = useState("");
    // const [fileUrl, setFileUrl] = useState("");
    const [loading, setLoading] = useState(false)
    const [branch, setBranch] = useState("");
    const [exerciseDetails, setExerciseDetails] = useState([{ exercise: '', sets: '', reps: '' }]);

    const handleChange = (e, index) => {
        setErrors({})
        const { name, value } = e.target;
        setExerciseDetails((prevExerciseDetails) => {
            const updatedDetails = [...prevExerciseDetails];
            updatedDetails[index][name] = value;
            return updatedDetails;
        });
    };

    const addExerciseRow = () => {
        setExerciseDetails([...exerciseDetails, { exercise: '', sets: '', reps: '' }]);

    };

    const removeExerciseRow = (index) => {
      
        if (index !== 0) {
            const updatedExerciseDetails = [...exerciseDetails];
            updatedExerciseDetails.splice(index, 1);
            setExerciseDetails(updatedExerciseDetails);
        }
    };

    const handleValidation = () => {
        let formIsValid = true;
        let newErrors = {};
      
        exerciseDetails.forEach((item, index) => {
          if (!/^[0-9]*$/.test(item.sets)) {
            formIsValid = false;
            newErrors = { ...newErrors, [`sets${index}`]: ERR_MESSAGE.INVALID_SETS };
          }
      
          if (!/^[:0-9]*$/.test(item.reps)) {
            formIsValid = false;
            newErrors = { ...newErrors, [`reps${index}`]: ERR_MESSAGE.INVALID_REPS };
          }
        });
      
        setErrors(newErrors);
        return formIsValid;
      };
      
     const exerciseRows = exerciseDetails.map((item, index) => (
        <div className="row align-items-end" key={index}>
            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                <div className="mb-4">
                    <label className="form-label">Exercise</label>
                    <select
                        name="exercise"
                        value={item.exercise}
                        onChange={(e) => handleChange(e, index)}
                        className="form-select common-input"
                    >
                        <option value="">Select</option>
                        <option value="Exercise 2">Exercise 2</option>
                        <option value="Exercise 3">Exercise 3</option>
                    </select>
                    {errors[`exercise${index}`] && <span className="err_msg">{errors[`exercise${index}`]}</span>}

                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                <div className="mb-4">
                    <label className="form-label">Sets</label>
                    <input
                        type="text"
                        name="sets"
                        value={item.sets}
                        onChange={(e) => handleChange(e, index)}
                        className="form-control common-input"
                        placeholder="Enter here"
                    />
                    {errors[`sets${index}`] && <span className="err_msg">{errors[`sets${index}`]}</span>}

                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                <div className="mb-4">
                    <label className="form-label">Reps/Duration</label>
                    <input
                        type="text"
                        name="reps"
                        value={item.reps}
                        onChange={(e) => handleChange(e, index)}
                        className="form-control common-input"
                        placeholder="Enter here"
                    />
                    {errors[`reps${index}`] && <span className="err_msg">{errors[`reps${index}`]}</span>}
                </div>

            </div>
            {index === 0 && (
                <div className="col-12 col-sm-6 col-lg-6 col-xl-2 ps-4">
                    <div className="mb-4">
                        <button className="addButton" onClick={addExerciseRow}>
                            <img src={ADD} alt="Add" />
                        </button>
                    </div>
                </div>
            )}
            <div className="col-12 col-sm-6 col-lg-6 col-xl-2 ps-4">
                {index !== 0 && (
                    <div className="mb-4">
                        <button className="addButton" onClick={() => removeExerciseRow(index)}>
                            <img src={REMOVE} alt="Remove" />
                        </button>
                    </div>
                )}
            </div>

        </div>
    ));
    // async function postApi(e) {
    //     e.preventDefault();
    //     let data = JSON.stringify({

    //     });
    //     setLoading(true);
    //     const result = await Services.postService("", data);

    //     if (result.code === DEVELOPMENT_CONFIG.statusCode) {
    //         setLoading(false);
    //         localStorage.setItem("message", result.message)
    //         navigate(ROUTES.LEADES);
    //         // setFields({
    //         //   ...fields,
    //         //   mobile_no: "",
    //         //   name: "",
    //         //   source: "",
    //         //   gender: "",
    //         //   prospectDate: "",
    //         //   location: "",
    //         //   conversation: "",
    //         //   callSubject: "",
    //         //   callResponse: "",
    //         //   reason: "",
    //         //   priority: "",
    //         //   nextCallDate: "",
    //         //   nextCallTime: "",
    //         //   callDate: "",
    //         //   callStatus: "",
    //         //   staff: "",
    //         //   remark: "",
    //         // });

    //     } else {
    //         setLoading(false);
    //         toast.error(result.message);
    //     }
    // }

    const fileChange = (e) => {
        setErrors({});
        if (e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("image", file);
                const imageURL = URL.createObjectURL(file);
                setImageUrl(imageURL)
                fileUpload(file)
            }
        }
    };
    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
            //   setFileUrl(fileUrl)
            }
        }
        catch (error) {
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if(handleValidation()){
 // postApi()
        }
           
      }

    return (
        <>
            <Helmet>
                <title>{TITLES.ADD_WORKOUT}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <div className='container-fluid my-4 pl-2'>
                <h5 className='mb-0 fw-600'>Workouts / Edit Workout</h5>

            </div>
            <section className='bg-white p-2 m-3 main-spacing mt-3 main-height'>
                <div className="container-fluid">
                    <div className="row pb-4 pt-4 pt-lg-0">
                        <div className='col-lg-3'>
                            <div className='branch-wrapper'>
                                <form>
                                    <h4 className='f18 fw-600'>Workout Image</h4>
                                    <div className="position-relative mb-3">
                                        <div className="profile-pic-change ">
                                            <div className="profile-upper-pic">
                                                <img src={imageUrl == "" ? ADDBRANCH : imageUrl} />
                                                {/* <p className="f14-size text-black fw-600">{!ADDBRANCH ? "" : "Upload Image"}</p> */}
                                            </div>
                                        </div>
                                        <input type="file" onChange={fileChange} className="input-profile" id="image" accept=".jpg, .jpeg, .png" />
                                    </div>
                                    <div className="col-12 ">
                                        <div className="mb-4">
                                            <label className="form-label">Workout Title</label>
                                            <select name="branch" value={branch} onChange={(e) => { setBranch(e.target.value) }} className="form-select common-input" >
                                                <option selected>Select</option>
                                                <option value="2">Action</option>
                                                <option value="1">Action</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className='col-lg-9'>

                            <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                            {exerciseRows}
                        </div>
                    </div>
                    <button type="button" class=" next-btn" onClick={handleSubmit}>Submit</button>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}




export default EditWorkout