const ROUTES = {
 
 CUSTOMERS: "/customers",
  CUSTOMER_PROFILE: "/customers/customer-profile",
  ADD_CUSTOMER: "/customers/add-customer",
  ADD_CUSTOMER_FULL: "/customers/add-customer-detail",
  EDIT_CUSTOMER_DETAIL: "/customers/edit-customer-detail",
  LEADES: "/leads",
  ADD_LEADS: "/leads/add-leads",
  EDIT_LEAD: "/leads/edit-lead",
  NEW_LEAD: "/new-lead",
  LOGIN: "/",
  STAFF:"/staff",
  ADD_STAFF:"/staff/add-staff",
  EDIT_STAFF:"/staff/edit-staff",
  WORKOUT:"/workout",
  ADD_WORKOUT:"/workout/add-workout",
  WORKOUT_DETAILS:"/workout/workout-detail",
  EDIT_WORKOUT:"/workout/edit-workout",
  EXERCISE:"/exercises",
  ADD_EXERCISE:"/exercises/add-exercise",
  DASHBOARD:"/dashboard",
  ATTENDENCE:"/attendence",
  ATTENDENCE_DETAIL:"/attendence/detail"
}
export default ROUTES