import React from 'react'
import { BIOMETRIC } from '../Middleware/images'
import moment from 'moment'
import DatePickerValue from './date-picker'

function About(props) {
    function formatRegistrationID(registrationId) {
        const idString = registrationId?.toString();
        const numberOfZeros = 4 - idString?.length;
        const formattedID = '0'.repeat(numberOfZeros) + idString;
        return formattedID;
    }
    return (
        <>
            <div className="tab-pane fade show active" id="v-tabs-About" role="tabpanel"
                aria-labelledby="v-tabs-About-tab">
                <section className="add-customer common-bg form-height about-top">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {
                                    props.editMode ? <>
                                        <form >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="my-4">
                                                        <h4 className="fw-600 personal-heading">Personal Detail</h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" name='name' value={props?.info?.name?.slice(0, 35)} onChange={props?.handleChange} className="form-control common-input" placeholder="Enter Name" />
                                                        {props.errors?.name && <span className="err_msg">{props.errors?.name}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Mobile No</label>
                                                        <input type="number" name='mobile_number' value={props.info.mobile_number?.slice(0, 10)} onChange={props.handleChange} className="form-control common-input" placeholder="Enter mobile No" />
                                                        {props.errors.mobile_number && <span className="err_msg">{props.errors.mobile_number}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">WhatsApp No</label>
                                                        <input type="text" name='whatsapp_no' value={props?.info.whatsapp_no?.slice(0, 10)} onChange={props?.handleChange} className="form-control common-input" placeholder="Enter WhatsApp No" />
                                                        {props.errors?.whatsapp_no && <span className="err_msg">{props.errors?.whatsapp_no}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Alternate No</label>
                                                        <input type="text" name='alternate_no' value={props?.info.alternate_no?.slice(0, 10)} onChange={props?.handleChange} className="form-control common-input" placeholder="Enter Alternate No" />
                                                        {props.errors?.alternate_no && <span className="err_msg">{props.errors?.alternate_no}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">DOB</label>
                                                        <DatePickerValue
                                                            setErrors={props.setErrors}
                                                            value={props.info.date_of_birth}
                                                            setFn={props.setDateOfBirth}
                                                        />
                                                        {props.errors?.date_of_birth && <span className="err_msg">{props.errors?.date_of_birth}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Refer By</label>
                                                        <select name='refer_by' value={props.info.refer_by} onChange={props.handleChange} className="form-select common-input" aria-label="Default select example">
                                                          
                                                                <option value="">Select Customer</option>
                                                        
                                                            {
                                                                props.referSymbol && <option value={props?.info?.refer_by}>{props.info.refer_by}</option>
                                                            }
                                                            {props.customerList
                                                                .filter(option => option.name !== props.info.refer_by)
                                                                .map((option, index) => (
                                                                    <option key={index} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Gender</label>
                                                        <select name='gender' value={props?.info.gender} className="form-select common-input" onChange={props?.handleChange}>
                                                            <option value={props?.info.gender}>{props?.info.gender == 1 ? "Male" : props?.info.gender == 2 ? "Female" : props?.info.gender == 3 ? "Others" : "Select"}</option>
                                                            {props?.info.gender === "2" ? null : <option value="2">Female</option>}
                                                            {props?.info.gender === "1" ? null : <option value="1">Male</option>}
                                                            {props?.info.gender === "3" ? null : <option value="3">Others</option>}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Height</label>
                                                        <input type="text" name='height' value={props?.info.height == 0.00 ? "" : props?.info.height} onChange={props?.handleChange} className="form-control common-input" placeholder="Enter Height" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" name='email' value={props?.info.email} onChange={props?.handleChange} className="form-control common-input" placeholder="Enter Email" />
                                                        {props.errors?.email && <span className="err_msg">{props.errors?.email}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Weight</label>
                                                        <input type="text" name='weight' value={props?.info.weight == 0.00 ? "" : props?.info?.weight} onChange={props?.handleChange} className="form-control common-input" placeholder="Enter Weight" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Address</label>
                                                        <textarea name='address_1' value={props?.info.address_1} onChange={props?.handleChange} className="form-control common-input remark-textarea" rows="3" placeholder="Enter Address"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="my-4">
                                                        <h4 className="fw-600 personal-heading">others Detail</h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Registration ID</label>
                                                        <input type="text" name='registration_id' value={formatRegistrationID(props?.info.registration_id)}
                                                            className="form-control common-input" placeholder="Enter Registration ID" />
                                                        {props.errors?.registration_id && <span className="err_msg">{props.errors?.registration_id}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Activity Level</label>
                                                        <select name='activity_level' value={props?.info.activity_level} onChange={props?.handleChange} className="form-select common-input">
                                                            {!props.info.activity_level && (
                                                                <option value="">Select</option>
                                                            )}
                                                            {props.info.activity_level && (
                                                                <option value={props.info.activity_level}>
                                                                    {props.info.activity_level}
                                                                </option>
                                                            )}
                                                            {['Rookie', 'Beginner', 'Intermediate', 'Advance', 'True Beast']
                                                                .filter(option => option !== props.info.activity_level)
                                                                .map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Prospect Source</label>
                                                        <select
                                                            name='prospect_source'
                                                            value={props?.info.prospect_source}
                                                            onChange={props?.handleChange}
                                                            className="form-select common-input"
                                                        >
                                                            {!props.info.prospect_source && (
                                                                <option value="">Select</option>
                                                            )}
                                                            {props.info.prospect_source && (
                                                                <option value={props.info.prospect_source}>
                                                                    {props.info.prospect_source}
                                                                </option>
                                                            )}
                                                            {['Banner', 'Blog', 'Email', 'Cable Advertisement', 'Facebook', 'Hoarding', 'Flyer', 'News Paper Ads', 'Passing by', 'Posters', 'Referral', 'SMS', 'Train Ads', 'Website', 'Word of Mouth', 'Instagram', 'Just Dial', 'Google Ad']
                                                                .filter(option => option !== props.info.prospect_source)
                                                                .map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Training Type</label>
                                                        <select
                                                            name='training_type'
                                                            value={props?.info.training_type}
                                                            onChange={props?.handleChange}
                                                            className="form-select common-input"
                                                        >
                                                            {!props.info.training_type && (
                                                                <option value="">Select</option>
                                                            )}
                                                            {props.info.training_type && (
                                                                <option value={props.info.training_type}>
                                                                    {props.info.training_type}
                                                                </option>
                                                            )}
                                                            {['General Training', 'Personal Training']
                                                                .filter(option => option !== props.info.training_type)
                                                                .map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Goal</label>
                                                        <select
                                                            name='goal'
                                                            value={props?.info.goal}
                                                            onChange={props?.handleChange}
                                                            className="form-select common-input"
                                                        >
                                                            {!props.info.goal && (
                                                                <option value="">Select</option>
                                                            )}
                                                            {props.info.goal && (
                                                                <option value={props.info.goal}>
                                                                    {props.info.goal}
                                                                </option>
                                                            )}
                                                            {['Gain Weight', 'Lose Weight', 'Get fitter', 'Gain more flexibility', 'Learn the basics']
                                                                .filter(option => option !== props.info.goal)
                                                                .map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Sale Rep</label>
                                                        <select
                                                            name='sales_person'
                                                            value={props?.info.sales_person}
                                                            onChange={props?.handleChange}
                                                            className="form-select common-input"
                                                        >
                                                            {!props.info.sales_person && (
                                                                <option value="">Select</option>
                                                            )}
                                                            {props.info.sales_person && (
                                                                <option value={props.info.sales_person}>
                                                                    {props.info.sales_person}
                                                                </option>
                                                            )}
                                                            {['Aditi Roy', 'karan Verma']
                                                                .filter(option => option !== props.info.sales_person)
                                                                .map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* <div className="col-12 col-sm-6 col-lg-4 d-none d-xl-block col-xl-3">
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 d-none d-xl-block col-xl-3">
                                                </div> */}
                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Biometric</label>
                                                        <div className="Biometric-img text-center common-input">
                                                            <img src={BIOMETRIC} className="img-fluid" />
                                                            <p className="add-biometric pt-2">Add</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-end align-self-end">
                                                    <button type="submit" className=" next-btn" onClick={props?.postApi}>Submit</button>
                                                </div>
                                            </div>
                                        </form>

                                    </>
                                        : <>
                                            <form >
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="my-4">
                                                            <h4 className="fw-600 personal-heading">Personal Detail</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Name</h5>
                                                            <p className='output-text'>{props?.info?.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Mobile No</h5>
                                                            <p className='output-text'>{props?.info?.mobile_number}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>WhatsApp No</h5>
                                                            <p className='output-text'>{props?.info?.whatsapp_no}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Alterate No</h5>
                                                            <p className='output-text'>{props?.info?.alternate_no}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>DOB</h5>
                                                            <p className='output-text'>{props?.info?.date_of_birth ? moment(props?.info?.date_of_birth).format('Do MMM, YYYY') : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Refer By</h5>
                                                            <p className='output-text'>{props?.info?.refer_by == 0 ? "" : props?.info?.refer_by}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Gender</h5>
                                                            <p className='output-text'>{props?.info?.gender == 1 ? "Male" : props?.info?.gender == 2 ? "Female" : props?.info?.gender == 3 ? "Others" : ""}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Height</h5>
                                                            <p className='output-text'>{props?.info.height == 0.00 ? "" : props?.info.height}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Email</h5>
                                                            <p className='output-text'>{props?.info?.email}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Weight</h5>
                                                            <p className='output-text'>{props?.info?.weight == 0.00 ? "" : props?.info?.weight}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Address</h5>
                                                            <p className='output-text'>{props?.info?.address_1}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="my-4">
                                                            <h4 className="fw-600 personal-heading">others Detail</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Registration ID</h5>
                                                            <p className='output-text'>{formatRegistrationID(props?.info.registration_id)
                                                            }</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Activity Level</h5>
                                                            <p className='output-text'>{props?.info?.activity_level}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Prospect Source</h5>
                                                            <p className='output-text'>{props?.info?.prospect_source}</p>

                                                        </div>
                                                    </div>
                                                    {/* <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Training Type</h5>
                                                            <p className='output-text'>{props?.info?.training_type}</p>

                                                        </div>
                                                    </div> */}
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Goal</h5>
                                                            <p className='output-text'>{props?.info?.goal}</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Sale Rep</h5>
                                                            <p className='output-text'>{props?.info?.sales_person}</p>

                                                        </div>
                                                    </div>

                                                    {/* <div className="col-12 col-sm-6 col-lg-4 d-none d-xl-block col-xl-3">
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-4 d-none d-xl-block col-xl-3">
                                                    </div> */}
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="mb-3">
                                                            <h5 className='label-text'>Biometric</h5>
                                                            <p className='output-text'>{props?.info?.biometric_id}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default About