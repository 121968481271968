// SidebarContext.js
import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <>
            <LayoutContext.Provider value={{
             searchQuery,   setSearchQuery,
            }}>
                {children}
            </LayoutContext.Provider>
        </>
    );
};

export const useLayoutContext = () => useContext(LayoutContext);
