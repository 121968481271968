import React, { useEffect, useState } from "react";
import {
  ADD_ICON,
  CROSS_BTN,
  EDIT_ICON,
  DELETE_ICON,
  CALL,
  MODAL_CROSS,
  EYE,
  SEARCH_ICON,
} from "../../../Middleware/images";
import "../../../assets/style/customer-leads.css";
import { useNavigate } from "react-router-dom";
import Services from "../../../Helper/services";
import DEVELOPMENT_CONFIG from "../../../Helper/config";
import ReactLoader from "../../../Loader/react-loader";
import ROUTES from "../../../Middleware/routes";
import { Helmet } from "react-helmet";
import TITLES from "../../../Helper/title";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../../components/pagination";
import moment from "moment";
import ERR_MESSAGE from "../../../Helper/error-helper";
import DatePickerValue from "../../../components/date-picker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  border: "1px solid #6ED7EF",
  p: 0,
  outline: 0,
  textAlign: "center",
};
const styleLead = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 650,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  border: "1px solid #6ED7EF",
  p: 0,
  outline: 0,
  overflow: "hidden",
  overflowY: "scroll"
};
function Leads() {
  let message = localStorage.getItem("message");
  const navigate = useNavigate();
  const [trainerList, setTrainerList] = useState([]);
  const [deltId, setDeltId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterItems, setFilterItems] = useState([])
  const [search, setSearch] = useState("")
  const [leadsList, setLeadsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 7;
  const [totalLeads, setTotalLeads] = useState("");
  const [totalLeads1, setTotalLeads1] = useState("");
  const totalPages = search == "" ? Math.ceil(totalLeads / pageSize) : Math.ceil(totalLeads1 / pageSize)
  const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1)
  const endIndex = search == "" ? Math.min(startIndex + pageSize - 1, totalLeads) : Math.min(startIndex + pageSize - 1, totalLeads1)
  const currentDate = new Date().toISOString().split('T')[0];
  const [errors, setErrors] = useState('');
  const [userChangedInfo, setUserChangedInfo] = useState(false);

  const [data1, setData1] = useState({
    discount_type: "",
    discount: "",
    base_amount: "",
    net_amount: "",
    paid_amount: "",
    balance_amount: "",
    month: "",
    days: "0",
    start_date: currentDate,
    end_date: "",
    mode_of_payment: "",
    transaction_id: "",
    training_type: "1",
    trainer_id:""

  })

  useEffect(() => {
    if (message) {
      toast.success(message);
      localStorage.removeItem("message");
    }
    return () => {
      message = null;
    };
  }, []);

  const handleChange2 = (e) => {
    setErrors('');
    const name = e.target.name;
    let value = e.target.value;
    if ((name === 'days' || name === 'month' || name === "base_amount" || name === 'net_amount' || name === 'paid_amount') && parseFloat(value) < 0) {
      value = 0;
    }
    if (name === 'month' && parseFloat(value) > 12) {
      value = 0;
    }
    if (name === 'discount') {
      const baseAmount = parseFloat(data1?.base_amount);
      value = Math.min(parseFloat(value), baseAmount);
    }
    if (name === 'net_amount') {
      const baseAmount = parseFloat(data1?.base_amount);
      value = Math.min(parseFloat(value), baseAmount);
    }
    if (name === 'paid_amount') {
      const netAmount = parseFloat(data1?.net_amount);
      value = Math.min(parseFloat(value), netAmount);
    }
    setData1((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    if (name === 'discount_type' && value === '2') {
      setData1((prevInfo) => ({
        ...prevInfo,
        discount: '',
      }));
    }

    if (name === 'base_amount' || name === 'discount_type' || name === 'discount') {
      calculateNetAmount();
    }

    if (name === 'net_amount' || name === 'paid_amount') {
      calculateBalance();
    }
    if (name === 'discount_type' && value === '1') {
      if (name === 'discount' && value > 100) {
        setData1((prevInfo) => ({
          ...prevInfo,
          discount: 100,
        }));
      }
    }

    if (name === "month" || name === "days") {
      setUserChangedInfo(true);
    }
  };


  const calculateNetAmount = () => {
    const baseAmount = Number(data1?.base_amount) || 0;
    const discount = Number(data1?.discount) || 0;

    if (data1?.discount_type === '1') {
      const discountAmount = (baseAmount * discount) / 100;
      const netAmount = baseAmount - discountAmount;
      setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
    }
    else if (data1?.discount_type === '2') {
      const discountAmount = data1.discount
      const netAmount = baseAmount - discountAmount;
      setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
    }
    else {
      setData1((prevInfo) => ({ ...prevInfo, net_amount: data1?.base_amount, paid_amount: data1?.base_amount }));
    }
  };
  async function getTrainerApi() {
    setLoading(true)
    let result = await Services.getService(`admin/trainer-list`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setTrainerList(result?.body?.data);
        setLoading(false);
    }
    else {
        setLoading(false);
    }
}

  const calculateBalance = () => {
    const netAmount = Number(data1?.net_amount) || 0;
    const paid_amount = Number(data1?.paid_amount) || 0;
    const balance_amount = netAmount - paid_amount;
    setData1((prevInfo) => ({ ...prevInfo, balance_amount: balance_amount }));
  };

  const handleStart = (newDate) => {
    if (data1.month > 0 || data1.days > 0) {
      const startDate = new Date(newDate) || currentDate;
      const month = parseInt(data1?.month || 0);
      const days = parseInt(data1?.days || 0);
      const totalDays = month * 30 + days;
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + totalDays);
      const formattedEndDate = endDate.toISOString().split('T')[0];
      setData1((prevFields) => ({
        ...prevFields,
        start_date: newDate,
        end_date: formattedEndDate,
      }));
    }
    else {
      setData1((prevFields) => ({
        ...prevFields,
        start_date: newDate,
        end_date: "",
      }));
    }
  };

  useEffect(() => {
    calculateNetAmount();
  }, [data1?.base_amount, data1?.discount_type, data1?.discount]);

  useEffect(() => {
    calculateBalance();
  }, [data1?.net_amount, data1?.paid_amount, data1?.base_amount]);

  useEffect(() => {
    setData1((prevInfo) => ({ ...prevInfo, paid_amount: data1?.net_amount }));
  }, [data1?.net_amount]);

  useEffect(() => {
    if (userChangedInfo) {
      handleStart(new Date().toISOString().split('T')[0]);
      setUserChangedInfo(false);
    }
  }, [data1?.month, data1?.days, userChangedInfo]);

  const setMemberSince = (newDate) => {
    setData1((prevFields) => ({
      ...prevFields,
      end_date: newDate,
    }));
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const { base_amount,
      month,
      paid_amount,
      net_amount,
      mode_of_payment,
      trainer_id
    } = data1
    if (data1.training_type == '2') {
      if (!trainer_id || trainer_id == undefined) {
          formIsValid = false;
          errors["trainer_id"] = ERR_MESSAGE.EMPTY_TRAINER
      }
  }
    if (!base_amount || base_amount.length === 0) {
      formIsValid = false;
      errors["base_amount"] = ERR_MESSAGE.EMPTY_BASEAMOUNT;
    }
    if (!net_amount || net_amount.length === 0) {
      formIsValid = false;
      errors["net_amount"] = ERR_MESSAGE.EMPTY_NETAMOUNT;
    }

    if (!paid_amount || paid_amount.length === 0) {
      formIsValid = false;
      errors["paid_amount"] = ERR_MESSAGE.EMPTY_PAIDAMOUNT;
    }
    if (!month) {
      formIsValid = false;
      errors["month"] = ERR_MESSAGE.EMPTY_PLANPERIOD;
    }
    // if (!start_date || start_date.length === 0) {
    //     formIsValid = false;
    //     errors["start_date"] = ERR_MESSAGE.EMPTY_start_date;
    // }

    if (!mode_of_payment || mode_of_payment.length === 0) {
      formIsValid = false;
      errors["mode_of_payment"] = ERR_MESSAGE.EMPTY_MODEOFPAYMENT;
    }
    setErrors(errors);
    return formIsValid;
  }

  async function getApiLead(e) {
    e.preventDefault()

    if (handleValidation()) {
      setLoading(true);
      let result = await Services.getService(
        `lead/convert-leads?id=${leadId}`
      );
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        postMembershipApi(result?.body);
        localStorage.setItem("message", result.message)
        navigate(ROUTES.CUSTOMERS);
      } else {
        setLoading(false);
      }
    }
  }

  async function postMembershipApi(id) {
    let data = JSON.stringify({
      "plan_type": data1.training_type ? data1.training_type : "1",
      "user_id": id,
      "discount_type": data1.discount_type,
      "discount": data1.discount,
      "base_amount": data1.base_amount,
      "net_amount": data1.net_amount,
      "paid_amount": data1.paid_amount,
      "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
      "month": data1.month,
      "days": data1.days ? data1.days : "0",
      "start_date": data1.start_date,
      "end_date": data1.end_date,
      "mode_of_payment": data1.mode_of_payment,
      "transaction_id": data1.transaction_id,
      "trainer_id": data1.trainer_id
    });
    setLoading(true);
    const result = await Services.postService("admin/purchase-membership", data);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      handleCloseLead()
      getApi()
      setData1({
        ...data1,
        discount_type: "",
        discount: "",
        base_amount: "",
        net_amount: "",
        paid_amount: "",
        balance_amount: "",
        month: "",
        days: "",
        start_date: "",
        end_date: "",
        mode_of_payment: "",
        transaction_id: ""
      })
    }
    else {
      setLoading(false);
      toast.error(result.message);
    }
  }
  useEffect(() => {
    getApi(currentPage);
    getTrainerApi()
  }, []);

  async function handleSearch(value) {
    let result = await Services.getService(`admin/search?lead=${value}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setFilterItems(result?.body)
      setTotalLeads1(result?.body?.length)
    }
    else {
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getApi(page);
  };
  const handleDeleteOpen = (id) => {
    setDeltId(id);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => setOpenDelete(false);

  const handleOpen = (id) => {
    setDeltId(id);
    setOpen(true);
    getDetailApi(id);
  };
  const role_id = localStorage.getItem("role_id")

  const handleClose = () => setOpen(false);
  const [leadId, setLeadId] = useState(null)
  const handleOpenLead = (id) => {
    setLeadId(id)
    setOpenLead(true);
  };
  const handleCloseLead = () => {
    setOpenLead(false)
    setData1({
      ...data1,
      discount_type: "",
      discount: "",
      base_amount: "",
      net_amount: "",
      paid_amount: "",
      balance_amount: "",
      month: "",
      days: "",
      start_date: "",
      end_date: "",
      mode_of_payment: "",
      transaction_id: ""
    })
  };

  async function getApi(pageNumber) {
    setLoading(true);
    let result = await Services.getService(
      `lead/get-leads?company_id=${localStorage.getItem(
        "company_id"
      )}&offset=${pageNumber}&limit=${7}`
    );
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setLeadsList(result?.body?.data);
      setTotalLeads(result.body?.total);
    } else {
      setLoading(false);
    }
  }
  const [fieldInfo, setFieldsInfo] = useState([]);
  const [fieldConversation, setFieldsConversation] = useState([]);

  async function getDetailApi(id) {
    setLoading(true);
    let result = await Services.getService(`lead/get-lead-details?id=${id}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setFieldsInfo(result?.body);
      setFieldsConversation(result?.body?.conversation_data[0]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDetailApi(deltId);
  }, []);

  async function getDeleteAPI() {
    setLoading(true);
    let result = await Services.getService(`lead/delete-leads?id=${deltId}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      handleDeleteClose();
      getApi(currentPage);
    } else {
      setLoading(false);
    }
  }

  const editLeadHandle = (id) => {
    navigate(ROUTES.EDIT_LEAD, {
      state: {
        id: id,
      },
    });
  };

  const handleBlur = (e) => {
    setData1({
      ...data1,
      [e.target.name]: 0
    })
  }

  function formatTime(timeString) {
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = new Date(
      `2000-01-01T${timeString}`
    ).toLocaleTimeString(undefined, timeOptions);
    return formattedTime;
  }

  function formatMobileNumber(mobileNumber) {
    if (mobileNumber && mobileNumber.length >= 10) {
      const firstDigits = mobileNumber.slice(0, 1);
      const lastDigits = mobileNumber.slice(-3);
      const formattedNumber = `${firstDigits} xxxxxx ${lastDigits}`;
      return formattedNumber;
    }
    return mobileNumber;
  }

  const handleEditFunction = (id) => {
    navigate(ROUTES.EDIT_LEAD, { state: { id } });
  };

  return (
    <>
      <Helmet>
        <title>{TITLES.LEADS}</title>
      </Helmet>
      {loading && <ReactLoader />}
      <section className="main-spacing mt-3 main-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 leads-table">
              <div className="row pb-2">
                <div className="col-12 col-md-6">
                  <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0">
                    <div className="leads-head">
                      <h3>Leads</h3>
                    </div>
                    <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3">
                      {/* <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">All Leads</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div> */}

                      {/* <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div> */}
                      <div className="search-category position-relative ">
                        <form className="d-flex" >
                          <input className="form-control ms-0 ms-sm-2 nav-input-search cus-nav-input-search" type="search" placeholder="Search leads..."
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value)
                              handleSearch(e.target.value)
                            }}
                          />
                          <img src={SEARCH_ICON} className="search-icon custom-search-icon" alt='' />
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="add-label text-end d-flex align-items-center justify-content-start justify-content-sm-end">
                    <button
                      type="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(ROUTES.ADD_LEADS);
                      }}
                      className="btn add-leads-btn d-flex align-items-center"
                    >
                      <img src={ADD_ICON} className="img-fluid me-2" alt='' /> Add New
                      Leads
                    </button>
                  </div>
                </div>
              </div>
              {
                search.length == 0 ? <>
                  <div className="table-responsive leads-table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="leads-table-head">
                            Name
                          </th>
                          <th scope="col" className="leads-table-head">
                            Call Response
                          </th>
                          <th scope="col" className="leads-table-head">
                            Prospect Date
                          </th>
                          <th scope="col" className="leads-table-head">
                            Source
                          </th>
                          <th scope="col" className="leads-table-head">
                            Gender
                          </th>
                          <th scope="col" className="leads-table-head">
                            Location
                          </th>
                          <th scope="col" className="leads-table-head">
                            Next call date & time
                          </th>
                          <th scope="col" className="leads-table-head">
                            Membership
                          </th>
                          <th scope="col" className="leads-table-head">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadsList?.map((items) => (
                          <tr key={items.id}>
                            <th
                              scope="row"
                              className="leads-data-head"
                              style={{ width: "200px" }}
                            >
                              {" "}
                              {items.name}{" "}
                              <p className="mobile-number">
                                {formatMobileNumber(items.mobile_no)}
                              </p>
                            </th>
                            <td className="leads-table-data">
                              {items?.conversation_data[0]?.call_response
                                ? items?.conversation_data[0]?.call_response
                                : "-"}
                            </td>
                            <td className="leads-table-data">
                              {" "}
                              {items.prospect_date
                                ? moment(items?.prospect_date).format(
                                  "Do MMM, YYYY"
                                )
                                : "-"}
                            </td>
                            <td className="leads-table-data">{items?.source}</td>
                            <td className="leads-table-data">
                              {items?.gender == 1 ? "Male" : items.gender == 2 ? "Female" : items.gender == 3 ? "Others" : "-"}                        </td>
                            <td className="leads-table-data">
                              {items?.location ? items?.location : "-"}
                            </td>

                            {/* <td className="leads-table-data">-</td> */}
                            <td className="leads-table-data">
                              {items?.conversation_data[0]?.next_call_date &&
                                items?.conversation_data[0]?.next_call_time ? (
                                <div>
                                  {moment(
                                    items?.conversation_data[0]?.next_call_date
                                  ).format("Do MMM, YYYY")}
                                  <br />
                                  {formatTime(
                                    items?.conversation_data[0]?.next_call_time
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>

                            {/* <td className="leads-table-data">{items?.conversation_data[0]?.priority ? items?.conversation_data[0]?.priority : "-"}</td> */}
                            <td><span className="upgrade-text" style={{ cursor: "pointer" }} onClick={() => handleOpenLead(items?.id)}>Convert to customer</span></td>

                            <td>
                              <div className="action-btns d-flex justify-content-center text-start align-items-center gap-3">
                                <img
                                  src={CALL}
                                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                  onClick={() => handleOpen(items.id)}
                                  alt=""
                                  className="img-fluid"
                                />
                                {
                                  role_id == "3" ? "" : <>   <img
                                    src={DELETE_ICON}
                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                    onClick={() => handleDeleteOpen(items.id)}
                                    alt=""
                                    className="img-fluid"
                                  /></>
                                }

                                <img
                                  src={EDIT_ICON}
                                  alt=""
                                  className="img-fluid"
                                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                  onClick={() => editLeadHandle(items.id)}
                                />
                                <div class="remarktip"> <img
                                  src={EYE}
                                  style={{ cursor: "pointer", width: "28px", height: "28px" }}
                                  alt=""
                                  className="img-fluid"
                                />
                                  <span class="remarktiptext">{items?.conversation_data[0]?.remarks ? items?.conversation_data[0]?.remarks : "no remark "} </span>
                                </div>

                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {leadsList?.length > 0 && (
                    <>
                      <div className="row pt-3">
                        <div className="col-6">
                          <span className="page-entry">
                            {totalLeads === 1 ? (
                              <>Showing {totalLeads} entry</>
                            ) : (
                              <>
                                Showing {startIndex} to {endIndex} of {totalLeads}{" "}
                                entries
                              </>
                            )}
                          </span>
                        </div>

                        <div className="col-6">
                          <nav aria-label="Page">
                            <ul className="pagination d-flex justify-content-end">
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                              />
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </>
                  )}

                </> : <>

                  <div className="table-responsive leads-table-responsive">
                    <table className="table">
                      <thead>
                        {
                          filterItems.length == 0 ? "" : <tr>
                            <th scope="col" className="leads-table-head">
                              Name
                            </th>
                            <th scope="col" className="leads-table-head">
                              Call Response
                            </th>
                            <th scope="col" className="leads-table-head">
                              Prospect Date
                            </th>
                            <th scope="col" className="leads-table-head">
                              Source
                            </th>
                            <th scope="col" className="leads-table-head">
                              Gender
                            </th>
                            <th scope="col" className="leads-table-head">
                              Location
                            </th>
                            <th scope="col" className="leads-table-head">
                              Next call date & time
                            </th>
                            <th scope="col" className="leads-table-head">
                              Membership
                            </th>
                            <th scope="col" className="leads-table-head">
                              Action
                            </th>
                          </tr>
                        }

                      </thead>
                      <tbody>
                        {filterItems?.length == 0 ? <div className='notFound'>No lead found</div> : filterItems?.map((items) => (
                          <tr key={items.id}>
                            <th
                              scope="row"
                              className="leads-data-head"
                              style={{ width: "200px" }}
                            >
                              {" "}
                              {items.name}{" "}
                              <p className="mobile-number">
                                {formatMobileNumber(items.mobile_no)}
                              </p>
                            </th>
                            <td className="leads-table-data">
                              {items?.conversation_data[0]?.call_response
                                ? items?.conversation_data[0]?.call_response
                                : "-"}
                            </td>
                            <td className="leads-table-data">
                              {" "}
                              {items.prospect_date
                                ? moment(items?.prospect_date).format(
                                  "Do MMM, YYYY"
                                )
                                : "-"}
                            </td>
                            <td className="leads-table-data">{items?.source}</td>
                            <td className="leads-table-data">
                              {items?.gender == 1 ? "Male" : items.gender == 2 ? "Female" : items.gender == 3 ? "Others" : "-"}
                            </td>
                            <td className="leads-table-data">
                              {items?.location ? items?.location : "-"}
                            </td>

                            {/* <td className="leads-table-data">-</td> */}
                            <td className="leads-table-data">
                              {items?.conversation_data[0]?.next_call_date &&
                                items?.conversation_data[0]?.next_call_time ? (
                                <div>
                                  {moment(
                                    items?.conversation_data[0]?.next_call_date
                                  ).format("Do MMM, YYYY")}
                                  <br />
                                  {formatTime(
                                    items?.conversation_data[0]?.next_call_time
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>

                            {/* <td className="leads-table-data">{items?.conversation_data[0]?.priority ? items?.conversation_data[0]?.priority : "-"}</td> */}
                            <td><span className="upgrade-text" style={{ cursor: "pointer" }} onClick={() => handleOpenLead(items?.id)}>Convert to customer</span></td>
                            <td>
                              <div className="action-btns d-flex justify-content-center text-start align-items-center gap-3">
                                <img
                                  src={CALL}
                                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                  onClick={() => handleOpen(items.id)}
                                  alt=""
                                  className="img-fluid"
                                />
                                {
                                  role_id == "3" ? "" : <>   <img
                                    src={DELETE_ICON}
                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                    onClick={() => handleDeleteOpen(items.id)}
                                    alt=""
                                    className="img-fluid"
                                  /></>
                                }

                                <img
                                  src={EDIT_ICON}
                                  alt=""
                                  className="img-fluid"
                                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                  onClick={() => editLeadHandle(items.id)}
                                />


                                <div class="remarktip"> <img
                                  src={EYE}
                                  style={{ cursor: "pointer", width: "28px", height: "28px" }}
                                  alt=""
                                  className="img-fluid"
                                />
                                  <span class="remarktiptext">{items?.conversation_data[0]?.remarks ? items?.conversation_data[0]?.remarks : "no remark "} </span>
                                </div>



                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {filterItems?.length > 0 && (
                    <>
                      <div className="row pt-3">
                        <div className="col-6">
                          <span className="page-entry">
                            {totalLeads1 === 1 ? (
                              <>Showing {totalLeads1} entry</>
                            ) : (
                              <>
                                Showing {startIndex} to {endIndex} of {totalLeads1}{" "}
                                entries
                              </>
                            )}
                          </span>
                        </div>

                        <div className="col-6">
                          <nav aria-label="Page">
                            <ul className="pagination d-flex justify-content-end">
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                              />
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </>
                  )}
                </>
              }

            </div>
          </div>
        </div>
      </section>
      <Modal
        open={openDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-head">
          <div
            onClick={handleDeleteClose}
            className=" position-relative mb-0 text-end pt-1"
            style={{ cursor: "pointer" }}
          >
            <img src={CROSS_BTN} alt='' />
          </div>
          <div className="signin-head text-center pb-3">
            <h2>Delete Lead</h2>
          </div>
          <div className="mb-4  position-relative">
            <p>
              Are you sure you want to delete the lead?

            </p>
          </div>
          <div
            className="mb-3 text-center position-relative submit-head"
            onClick={getDeleteAPI}
          >
            <button type="submit" className="btn delete-btn">
              Delete
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-head">
          <div
            onClick={handleClose}
            className=" position-relative mb-0 text-end close-modal-btn"
            style={{ cursor: "pointer" }}
          >
            <img src={MODAL_CROSS} className="img-fluid" alt='' />
          </div>
          <div className="modal-staff-header position-relative text-center">
            <h2 className="customer-modal-text fw-600">Lead Detail</h2>
          </div>
          <h2 className="user-name f18 text-center fw-600 mb-2">
            {fieldInfo?.name}
          </h2>

          <div className="leads-listing">
            <div className="px-3">
              <div className="row">
                <div className="col-12">
                  <div className="basic-info-head text-start py-3">
                    <h4 className="fw-600">Basic Info</h4>
                  </div>
                </div>
              </div>
              {fieldInfo?.gender && (
                <div className="border-bottom mb-1">
                  <div className="row">
                    <div className="col-md-6  col-6 py-2">
                      <h6 className="fw-500 f16-size text-start text-black">
                        Gender
                      </h6>
                    </div>
                    <div className="col-md-6 text-end py-2 col-6">
                      <p className="fw-400 f16-size text-grey text-end trainerAbout">
                        {fieldInfo?.gender == 1 ? "Male" : fieldInfo.gender == 2 ? "Female" : fieldInfo.gender == 3 ? "Others" : "-"}                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="border-bottom mb-1">
                <div className="row">
                  <div className="col-md-6  col-6 py-2">
                    <h6 className="fw-500 f16-size text-start text-black">
                      Mobile Number
                    </h6>
                  </div>
                  <div className="col-md-6 text-end py-2 col-6">
                    <p className="fw-400 f16-size text-grey text-end trainerAbout">
                      {fieldInfo?.mobile_no}
                    </p>
                  </div>
                </div>
              </div>
              {
                fieldInfo?.location &&
                <div className="border-bottom mb-1">
                  <div className="row">
                    <div className="col-md-6  col-6 py-2">
                      <h6 className="fw-500 f16-size text-start text-black">
                        Address
                      </h6>
                    </div>
                    <div className="col-md-6 text-end py-2 col-6">
                      <p className="fw-400 f16-size text-grey text-end trainerAbout">
                        {fieldInfo?.location}
                      </p>
                    </div>
                  </div>
                </div>
              }

              <div className="border-bottom mb-1">
                <div className="row">
                  <div className="col-md-6  col-6 py-2">
                    <h6 className="fw-500 f16-size text-start text-black">
                      Prospect Date
                    </h6>
                  </div>
                  <div className="col-md-6 text-end py-2 col-6">
                    <p className="fw-400 f16-size text-grey text-end trainerAbout">
                      {moment(fieldInfo?.prospect_date).format("Do MMM, YYYY")}
                    </p>
                  </div>
                </div>
              </div>
              {
                fieldInfo?.source &&
                <div className="border-bottom mb-1">
                  <div className="row">
                    <div className="col-md-6  col-6 py-2">
                      <h6 className="fw-500 f16-size text-start text-black">
                        Source
                      </h6>
                    </div>
                    <div className="col-md-6 text-end py-2 col-6">
                      <p className="fw-400 f16-size text-grey text-end trainerAbout">
                        {fieldInfo?.source}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className="py-1 px-3">
              <div className="row">
                <div className="col-md-6  col-6 py-2">
                  <h4 className="fw-600 text-start text-black">
                    Conversation
                  </h4>
                </div>
                <div className="col-md-6 text-end  col-6 py-2">
                  <p className="fw-600 f16-size text-black text-end">
                    {" "}
                    {fieldInfo?.conversation == 1 ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>

            {fieldInfo?.conversation == 1 && (
              <div className="address-detail px-3">
                {fieldConversation?.call_date && (
                  <div className="border-bottom">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Call Date
                        </h6>
                      </div>
                      <div className="col-md-6 text-end  col-6 py-2">
                        <p className="fw-400 f16-size text-grey text-end">
                          {" "}
                          {moment(fieldConversation?.call_date).format(
                            "Do MMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {fieldConversation?.call_response && (
                  <div className="border-bottom mb-1">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Call Response
                        </h6>
                      </div>
                      <div className="col-md-6 text-end py-2   col-6">
                        <p className="fw-400 f16-size text-grey text-end">
                          {fieldConversation?.call_response}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {fieldConversation?.priority && (
                  <div className="border-bottom mb-1">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Priority
                        </h6>
                      </div>
                      <div className="col-md-6 text-end py-2 col-6">
                        <p className="fw-400 f16-size text-grey text-end trainerAbout">
                          {fieldConversation?.priority}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {fieldConversation?.next_call_date && (
                  <div className="border-bottom mb-1">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Next Call Date
                        </h6>
                      </div>
                      <div className="col-md-6 text-end py-2 col-6">
                        <p className="fw-400 f16-size text-grey text-end trainerAbout">
                          {moment(fieldConversation?.next_call_date).format(
                            "Do MMM, YYYY"
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {fieldConversation?.next_call_time && (
                  <div className="border-bottom mb-1">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Next call time
                        </h6>
                      </div>
                      <div className="col-md-6 text-end py-2 col-6">
                        <p className="fw-400 f16-size text-grey text-end trainerAbout">

                          {formatTime(
                            fieldConversation?.next_call_time
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {fieldConversation?.user?.name ? (
                  <div className="border-bottom mb-1">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Staff assign
                        </h6>
                      </div>
                      <div className="col-md-6 text-end py-2 col-6">
                        <p className="fw-400 f16-size text-grey text-end trainerAbout">
                          {fieldConversation?.user?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {fieldConversation?.remarks && (
                  <div className="border-bottom mb-1 py-2">
                    <div className="row">
                      <div className="col-md-6  col-6 py-2">
                        <h6 className="fw-500 f16-size text-start text-black">
                          Remark
                        </h6>
                      </div>
                      <div className="col-md-6 text-end py-2 col-6">
                        <p className="fw-400 f16-size text-grey text-end trainerAbout">
                          {fieldConversation?.remarks}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className="col-12 d-flex justify-content-end align-self-end py-3 mb-1 px-3 text-center position-relative "
            onClick={() => handleEditFunction(fieldInfo.id)}
          >
            <button type="submit" className="btn delete-btn  w-100">
              Edit
            </button>
          </div>

        </Box>
      </Modal>
      <Modal
        open={openLead}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLead} className="modal-head">
          <div
            onClick={handleCloseLead}
            className=" position-relative mb-0 text-end pt-1 pe-1"
            style={{ cursor: "pointer" }}
          >
            <img src={CROSS_BTN} alt='' />
          </div>
          <div className="signin-head text-center pb-3">
            <h2 className='modalHeading'>Purchase Membership</h2>
          </div>

          <form className='create-field purchase-create-field'>
            <div className='row'>
              <div className="col-md-12">
                <div className="mb-2 mb-xxl-3">
                  <label className="form-label mb-0">Training type </label>
                  <select name="training_type" className="form-select common-input" value={data1?.training_type} onChange={handleChange2} >
                    <option value="1">General training</option>
                    <option value="2">Personal training</option>
                  </select>
                </div>
              </div>
              {
                data1?.training_type == 2 && <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0">Trainer </label>

                    <select name='trainer_id' value={data1.trainer_id} onChange={handleChange2} className="form-select common-input" aria-label="Default select example" >
                      <option value="">Select Trainer</option>
                      {
                        trainerList?.map((item) => {
                          return (
                            <>
                              <option key={item.id} value={item.id}>{item.name}</option>
                            </>
                          )
                        })
                      }

                    </select>
                    {errors.trainer_id && <span className="err_msg">{errors.trainer_id}</span>}
                  </div>
                </div>
              }
              <div className="col-md-12">
                <div className="mb-2 mb-xxl-3">
                  <label className="form-label mb-0">Base Amount</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className='dollar-Sign'>₹</span>
                    </div>
                    <input
                      type="number"
                      name="base_amount"
                      value={data1?.base_amount?.slice(0, 15)}
                      onChange={handleChange2}
                      className="form-control common-input dollarInput"
                      placeholder="Enter Price"
                    />
                  </div>
                  {errors.base_amount && <span className="err_msg">{errors.base_amount}</span>}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3">
                  <label className="form-label mb-0">DISCOUNT type </label>
                  <select name="discount_type" className="form-select common-input" value={data1?.discount_type} onChange={handleChange2} >
                    <option value="">Select</option>
                    <option value="1">%(Percentage)</option>
                    <option value="2">Lump sum</option>
                  </select>
                  {errors.discount_type && <span className="err_msg">{errors.discount_type}</span>}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">DISCOUNT</label><input type="number" className="form-control common-input" placeholder="Enter Discount" value={data1?.discount} onChange={handleChange2} name="discount" disabled={!data1?.discount_type} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Net amount</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className='dollar-Sign'>₹</span>
                    </div>
                    <input
                      type="number"
                      name="net_amount"
                      value={data1?.net_amount}
                      onChange={handleChange2}
                      readOnly={data1?.discount_type == "1" || !data1?.discount_type || data1.discount_type == "2"} className="form-control common-input dollarInput"
                      placeholder="Enter Price"
                    />
                  </div>
                  {errors.net_amount && <span className="err_msg">{errors.net_amount}</span>}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">PAID amount</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className='dollar-Sign'>₹</span>
                    </div>
                    <input
                      type="number" placeholder="Enter paid amount" value={data1?.paid_amount} onChange={handleChange2} name="paid_amount"
                      className="form-control common-input dollarInput"
                    />
                  </div>
                  {errors.paid_amount && <span className="err_msg">{errors.paid_amount}</span>}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Balance amount</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className='dollar-Sign'>₹</span>
                    </div>
                    <input
                      type="number" placeholder="Enter balance amount" value={data1?.balance_amount}

                      name="balance_amount"
                      className="form-control common-input dollarInput"
                      onChange={(e) => {
                        handleChange2(e);

                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">MONTH</label><input type="number" className="form-control common-input" placeholder="Enter Month" value={data1?.month} onChange={handleChange2} name="month" />
                  {errors.month && <span className="err_msg">{errors.month}</span>}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Days</label><input type="number" onBlur={handleBlur} className="form-control common-input" placeholder="Enter days" value={data1?.days}
                  onChange={handleChange2} name="days" />

                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">START date</label>
                  <DatePickerValue
                    setErrors={setErrors}
                    value={data1?.start_date ? data1.start_date : currentDate}
                    setFn={handleStart}
                  />
                  {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                  <DatePickerValue
                    setErrors={setErrors}
                    value={data1?.end_date}
                    setFn={setMemberSince}
                    endDate={data1?.end_date}
                    name="planEnd"
                  />

                  {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2 mb-xxl-3">
                  <label className="form-label mb-0">PAYMENT MODE</label>
                  <select name="mode_of_payment" className="form-select common-input" value={data1?.mode_of_payment} onChange={handleChange2} >
                    <option selected>Select</option>
                    <option value="cash">Cash</option>
                    <option value="gpay">Gpay</option>
                    <option value="Others">Others</option>
                  </select>
                  {errors.mode_of_payment && <span className="err_msg">{errors.mode_of_payment}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">TRANSACTION ID</label><input type="text" value={data1?.transaction_id} onChange={handleChange2} name="transaction_id" className="form-control common-input" placeholder="Enter ID" />
                  {errors.transaction_id && <span className="err_msg">{errors.transaction_id}</span>}
                </div>

              </div>
            </div>


          </form>
          <div className="mt-4 mb-3 text-center position-relative submit-head">
            <button onClick={getApiLead}
              type="submit"
              className="common-btnn w-100"
            >
              Submit
            </button>
          </div>
        </Box>

      </Modal>

      <ToastContainer />
    </>
  );
}
export default Leads;

