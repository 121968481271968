import React from 'react'
import { CLOCK_ICON} from "../Middleware/images"
const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function Workout(props) {
    return (
        <div className="row">
            <div className="days-head d-flex " style={{ cursor: "pointer" }}>
                {daysOfWeek.map((day, index) => (<div className="days-content" key={index} onClick={() => props.handleDayClick(day)}>
                    <p className={`f18-size  days-text ${day === props.activeDay ? "tabActve" : ""}`}>
                        {day}
                    </p>
                </div>
                ))}
                <div className="days-content " onClick={() => props.handleDayClick("Sun")}>
                <p className={`f18-size  days-text ${"Sun" === props.activeDay ? "tabActve" : ""}`}>
                        Sun
                    </p>
                </div>
            </div>

            {/* <div className="col-12">
                        <div className="d-flex justify-content-between my-3">
                            <p className="work-detail f14-size fw-600">Workout detail</p> */}
            {/* <a href=""><img src={EDIT_ICON} /></a> */}
            {/* </div>
                    </div> */}
            {/* <div className="col-12">
                        <h4 className="fw-600 work-title">{props.workoutDetail.workout_name}</h4>
                    </div>
                    <div className="col-12">
                        <div className="trainig-content d-flex gap-3 align-items-center">
                            <div className='d-flex gap-1 align-items-start'><img src={CLOCK_ICON} className="img-fluid" /><p> {props.workoutDetail.duration} min, {props.workoutDetail.activity_level}</p></div> */}
            {/* <div className='d-flex gap-1 align-items-start'><img src={MESSAGE_ICON} className="img-fluid" /><p> Consultation with trainer</p></div> */}
            {/* <div className='d-flex gap-1 align-items-start'><img src={DUMBLE_ICON} className="img-fluid" /><p> {props?.workoutDetail.no_of_exercise} exclusive  {props?.workoutDetail.no_of_exercise === 1 ? "training" : "trainings"} </p></div>
                        </div>
                    </div>
                     */}
            {
                props?.excerciseList?.length > 0 && <>
                    <div className="col-12">
                        <p className="f16-size fw-600 my-2"> {props?.excerciseList?.length} {props?.excerciseList?.length === 1 ? "Exercise" : "Exercises"} </p>
                    </div>
                </>
            }

            {
                props?.excerciseList === undefined || props.excerciseList.length === 0 ? <><p className='text-center my-5 f18 fw-500'>No exercises available </p>
                </> : <>
                    <div className="training-card">
                        {
                            props?.excerciseList?.map((item) => (
                                <div className="trainig-card-head mb-4" key={item.id}>
                                    {item?.url?.includes('.mp4') ? (
                                        <video width="255" height="170" controls className='border10'>
                                            <source src={item.url} type="video/mp4" className='input-profile-video' />
                                        </video>
                                    ) : (
                                        <img src={item.url} className="training-demo " />
                                    )}

                                    <div className="trainig-card-content px-2">
                                        <h4>{item.exercise_name}</h4>
                                        <div className="training-card-text d-flex justify-content-between pb-3">
                                            <p>{item?.exercise_details?.sets} Sets x {item?.exercise_details?.reps} Reps</p>
                                            <p><img src={CLOCK_ICON} className="img-fluid" /> {item?.exercise_details?.duration} min</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default Workout