import React from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Login from './pages/auth/login';
import Leads from './pages/admin/leads/Leads';
import Layout from './layout';
import ROUTES from "./Middleware/routes"
import CustomerProfile from './pages/admin/Customer/customerProfile';
import AddCustomers from './pages/admin/Customer/addCustomers';
import AddLeads from './pages/admin/leads/addLeads';
import "./assets/style/global.css";
import "./assets/style/responsive.css";
import NotFound from './pages/notFound/notFound';
import "./assets/style/modal.css"
import EditCustomer from './pages/admin/Customer/editCustomer';
import Customers from './pages/admin/Customer/customers';
import EditLead from './pages/admin/leads/editLead';
import Staff from './pages/admin/Staff/staff';
import AddStaff from './pages/admin/Staff/addStaff';
import EditStaff from './pages/admin/Staff/editStaff';
import WorkoutPage from './pages/admin/Workout/workout';
import AddWorkout from './pages/admin/Workout/addWorkout';
import WorkoutDetail from './pages/admin/Workout/workoutDetail';
import Excersice from './pages/admin/Excercise/excercise';
import EditWorkout from './pages/admin/Workout/editWorkout';
import Dashboard from './pages/admin/Customer/Dashboard/dashboard';
import { LayoutProvider } from './context/context';
import Attendence from './pages/admin/Attendence/attendence';
import AttendenceDetail from './pages/admin/Attendence/detailAttendence';
const PrivateRoutes = () => {
  return (
    <>
      <LayoutProvider>
        <Layout>
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path={ROUTES.CUSTOMERS} element={<Customers />} />
            <Route path={ROUTES.CUSTOMER_PROFILE} element={<CustomerProfile />} />
            <Route path={ROUTES.ADD_CUSTOMER} element={<AddCustomers />} />
            <Route path={ROUTES.EDIT_CUSTOMER_DETAIL} element={<EditCustomer />} />
            <Route path={ROUTES.LEADES} element={<Leads />} />
            <Route path={ROUTES.ADD_LEADS} element={<AddLeads />} />
            <Route path={ROUTES.EDIT_LEAD} element={<EditLead />} />
            <Route path={ROUTES.STAFF} element={<Staff />} />
            <Route path={ROUTES.ADD_STAFF} element={<AddStaff />} />
            <Route path={ROUTES.EDIT_STAFF} element={<EditStaff />} />
            <Route path={ROUTES.WORKOUT} element={<WorkoutPage />} />
            <Route path={ROUTES.ADD_WORKOUT} element={<AddWorkout />} />
            <Route path={ROUTES.WORKOUT_DETAILS} element={<WorkoutDetail />} />
            <Route path={ROUTES.EXERCISE} element={<Excersice />} />
            <Route path={ROUTES.EDIT_WORKOUT} element={<EditWorkout />} />
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.ATTENDENCE} element={<Attendence />} />
            <Route path={ROUTES.ATTENDENCE_DETAIL} element={<AttendenceDetail />} />
          </Routes>
        </Layout>
      </LayoutProvider>
    </>
  );
}

function App() {
  const token = localStorage.getItem("access_token");
  const location = useLocation();
  if (location.pathname == '/') {
    if (token != null) {
      return (<Navigate to={ROUTES.CUSTOMERS} />)
    }
  }

  return (
    <>
      {token != null ?
        <PrivateRoutes /> :
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path='*' element={<NotFound />} />
        </Routes >
      }
    </ >
  );
}

export default App;
