import React, { useEffect, useState } from 'react'
import "../../../assets/style/workout.css"
import { ADD_ICON, CLOCK_ICON } from "../../../Middleware/images";
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Middleware/routes';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
import ReactLoader from '../../../Loader/react-loader';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../components/pagination';

const WorkoutPage = () => {
    let message = localStorage.getItem("message")
    const [total, setTotal] = useState("")
    const [excerciseList, setExcerciseList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1);
    const endIndex = Math.min(startIndex + pageSize - 1, total);
    const excerciseLists = excerciseList;
    const totalPages = Math.ceil(total / pageSize);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (message) {
            toast.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        };
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getWorkoutApi(page);
    };
    async function getWorkoutApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`workout/workouts-listing?&offset=${pageNumber}&limit=${10}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseList(result?.body?.data);
            setTotal(result.body.total)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    useEffect(() => {
        getWorkoutApi(currentPage)
    }, [])

    const handleEdit = (id) => {
        navigate(ROUTES.WORKOUT_DETAILS, { state: { id } })
    }

    return (
        <>
            <Helmet>
                <title>{TITLES.WORKOUT}</title>
            </Helmet>
            {loading &&
                <ReactLoader />
            }
            <section className="main-spacing mt-3 main-height">
                <div className="container-fluid leads-table">
                    <div className="row pb-2">
                        <div className="col-12 col-md-6">
                            <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0">
                                <div className="leads-head">
                                    <h3>Workouts</h3>
                                </div>
                                <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3">
                                    {/* <div>
                                        <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">All Workout</button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>

                                    <div>
                                        <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6" onClick={() => navigate(ROUTES.ADD_WORKOUT)}>
                            <div className="add-label text-end d-flex align-items-center justify-content-start justify-content-sm-end">
                                <button type="button" className="btn add-leads-btn d-flex align-items-center" ><img src={ADD_ICON} alt='' className="img-fluid me-2" /> Add New Workout</button>
                            </div>
                        </div>
                    </div>

                    <div className='trainer-height exercisCard'>
                        <div className="training-card">
                            {
                                excerciseLists?.map((item) => (
                                    <div className="trainig-card-head mb-4" style={{ cursor: "pointer" }} key={item.id} onClick={() => handleEdit(item.id)}>
                                        <img src={item.image_url} className="training-demo" />
                                        <div className="trainig-card-content px-2">
                                            <h4>{item.workout_name}</h4>
                                            <div className="training-card-text d-flex justify-content-between pb-3">
                                                <p>{item.no_of_exercise}  {item.no_of_exercise == 1 ? "workout" : "workouts"}</p>
                                                <p><img src={CLOCK_ICON} className="img-fluid" alt='' /> {item.duration} min</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        excerciseLists?.length > 0 && <>
                            <div className="row pt-3">
                                <div className="col-6">
                                    <a href=""><span className="page-entry">
                                        {
                                            total === 1 ? <>
                                                Showing {total} entry

                                            </> :
                                                <>
                                                    Showing {startIndex} to {endIndex} of {total} entries

                                                </>
                                        }
                                    </span>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <nav aria-label="Page">
                                        <ul className="pagination d-flex justify-content-end">
                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>

            <ToastContainer />
        </>
    )
}




export default WorkoutPage