import React, { useEffect, useState } from 'react'
import "../../../assets/style/excercise.css"
import { ADD_ICON, CROSS_BTN, ADDBRANCH, ADD_IMG } from "../../../Middleware/images";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
import uploadFileToS3 from '../../../Helper/fileUpload';
import ERR_MESSAGE from '../../../Helper/error-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import Services from '../../../Helper/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoader from '../../../Loader/react-loader';
import Pagination from '../../../components/pagination';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
    height: 480,
    overflow: "hidden",
    overflowY: "scroll"
};

const Excersice = () => {
    const [errors, setErrors] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState('');
    const [fileUrl, setFileUrl] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [excerciseList, setExcerciseList] = useState([]);
    const [excerciseDetail, setExcerciseDetail] = useState({})
    const [total, setTotal] = useState("")
    // const [deltId, setDeltId] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [url_type, setUrlType] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1);
    const endIndex = Math.min(startIndex + pageSize - 1, total);
    const excerciseLists = excerciseList;
    const totalPages = Math.ceil(total / pageSize);
    const handlePageChange = (page) => {
        setCurrentPage(page);
        getApi(page);
    };
    const [fields, setFields] = useState({
        time: "",
        name: ""
    })

    const handleOpen = (e, id) => {
        const clickedElement = e.target;
        // setDeltId(id);
        if (clickedElement.tagName.toLowerCase() === 'video') {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
            getDetailApi(id);
        } else {
            setOpen(true);
            getDetailApi(id);
        }
    };

    const handleClose = () => setOpen(false);

    const handleDeleteOpen = () => {
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setErrors("")
        setOpenDelete(false);
        setFields({
            ...fields,
            time: "",
            name: ""
        })
        setImageUrl("")
        setVideoUrl("")
    }

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setFields({
            ...fields,
            [name]: value
        })
    }
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const regex = /^[a-zA-Z\s]*$/;
        const { name } = fields
        if (!imageUrl && !videoUrl) {
            formIsValid = false;
            errors["imageUrl"] = ERR_MESSAGE.EMPTY_IMAGE1;
        }
        if (!name) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_EXCERCISENAME;
        }
        else if (!regex.test(name)) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_EXCERCISENAME;
        }

        // if (!time) {
        //     formIsValid = false;
        //     errors["time"] = ERR_MESSAGE.EMPTY_TIME;
        // }
        // else if (!/^[:0-9]+$/.test(time)) {
        //     formIsValid = false;
        //     errors["time"] = ERR_MESSAGE.INVALID_TIME;
        // }

        setErrors(errors);
        return formIsValid;
    }


    const fileChange = (e) => {
        setErrors("")
        if (e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];

        if (file) {
            const fileType = file.type.split('/')[0];

            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("mediaFile", file);
                const imageURL = URL.createObjectURL(file);
                setImageUrl(imageURL);
                setVideoUrl("")
                setUrlType("1")
                fileUpload(file);
            } else if (fileType === 'video') {
                const formData = new FormData();
                formData.append("mediaFile", file);
                const videoURL = URL.createObjectURL(file);
                setVideoUrl(videoURL);
                setImageUrl("")
                setUrlType("2")
                fileUpload(file);
            }
        }
    };

    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
                setFileUrl(fileUrl)
            }
        }
        catch (error) {
        }
    };

    useEffect(() => {
        const videoElement = document.getElementById('videoElement');
        if (videoElement) {
            videoElement.src = videoUrl;
            videoElement.load();
        }
    }, [videoUrl]);

    async function postApi(e) {
        e.preventDefault()
        if (handleValidation()) {
            let data = JSON.stringify(
                {
                    "exercise_name": fields.name,
                    "url_type": url_type,
                    "url": fileUrl,
                });
            setLoading(true);
            const result = await Services.postService("workout/add-exercise", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleDeleteClose()
                getApi(currentPage)
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
     }
    async function getApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`workout/all-exercise?&offset=${pageNumber}&limit=${10}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseList(result?.body?.data);
            setTotal(result?.body?.total)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    async function getDetailApi(id) {
        setLoading(true)
        let result = await Services.getService(`workout/exercise-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseDetail(result?.body);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    useEffect(() => {
        getApi(currentPage)
    }, [])

    return (
        <>
            {loading &&
                <ReactLoader />
            }
            <Helmet>
                <title>{TITLES.EXCERCISE}</title>
            </Helmet>
            <section className="main-spacing mt-3 main-height">
                <div className="container-fluid leads-table">
                    <div className="row pb-2 mb-3">
                        <div className="col-12 col-md-6">
                            <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0">
                                <div className="leads-head">
                                    <h3>Exercise</h3>
                                </div>
                                <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3">
                                    {/* <div>
                                        <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Newest</button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Newest</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>

                                    <div>
                                        <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6" >
                            <div className="add-label text-end d-flex align-items-center justify-content-start justify-content-sm-end">
                                <button type="button" onClick={handleDeleteOpen} className="btn add-leads-btn d-flex align-items-center" ><img src={ADD_ICON} className="img-fluid me-2" alt="" /> Add New Exercise</button>
                            </div>
                        </div>
                    </div>
                    <div className='trainer-height exercisCard'>
                        <div className="training-card" style={{cursor:"pointer"}}>
                            {
                                excerciseLists?.map((item) => (
                                    <div className="trainig-card-head mb-4" key={item?.id} onClick={(e) => handleOpen(e, item?.id)}>
                                        {item.url.includes('.mp4') ? (
                                            <video width="281" height="190" controls>
                                                <source src={item.url} type="video/mp4" className='input-profile-video' />
                                            </video>
                                        ) : (
                                            <img src={item.url}  alt=""  className="training-demo" />
                                        )}
                                        <div className="trainig-card-content px-2">
                                            <h4>{item?.exercise_name}</h4>

                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>

                    {
                        excerciseLists?.length > 0 && <>
                            <div className="row pt-3">
                                <div className="col-6">
                                    <a href=""><span className="page-entry">
                                        {
                                            total === 1 ? <>
                                                Showing {startIndex} {total} entry

                                            </> :
                                                <>
                                                    Showing {startIndex} to {endIndex} of {total} entries

                                                </>
                                        }
                                    </span>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <nav aria-label="Page">
                                        <ul className="pagination d-flex justify-content-end">
                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN}  alt="" />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Excercise Detail</h2>
                    </div>
                    <div className='exercise-modal-body px-3'>
                        <h3 className='f18 fw-600 text-start text-uppercase my-2'>Images / Videos</h3>
                        {excerciseDetail?.url_type === "2" ? (
                            <video width="254" height="220" controls>
                                <source src={excerciseDetail?.url} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={excerciseDetail?.url}  alt=""  className='detailimg my-2' />
                        )}

                        <div className='mt-2'>
                            <div className="mb-3 text-start">
                                <label className="form-label ">EXCERCISE NAME </label>
                                <input type="text" className="form-control common-input" placeholder="Enter here" value={excerciseDetail?.exercise_name} />
                            </div>
                        </div>
                    </div>

                    <div
                        className="mb-3 text-center position-relative submit-head"
                    >
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleDeleteClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN}  alt="" />
                    </div>
                    <div className="signin-head text-left pb-3">
                        <h2 >Add Excercise</h2>
                    </div>
                    <div className='exercise-modal-body px-3'>
                        <h3 className='f18-size fw-600 text-uppercase text-start'>Images / Videos</h3>

                        <div className="position-relative mb-3">
                            <div className="profile-pic-change ">
                                <div className="profile-upper-pic execise-img">
                                    {(!imageUrl && !videoUrl) && <img src={ADD_IMG} className='img-fluid' alt="Placeholder" />}
                                    {imageUrl && <img src={imageUrl == "" ? ADDBRANCH : imageUrl} className='img-fluid'  alt="" />}
                                    {videoUrl && (
                                        <video id="videoElement" width="340" height="240" controls>
                                            <source src={videoUrl} type="video/mp4" />
                                        </video>
                                    )}
                                </div>
                            </div>
                            <input
                                type="file"
                                onChange={fileChange}
                                className={videoUrl === "" ? "input-profile" : "input-profile-video"}
                                id="image"
                                accept=".jpg, .jpeg, .png, .mp4, .mov, .avi"
                            />
                            {errors.imageUrl && <span className="err_msg">{errors.imageUrl}</span>}
                        </div>
                        <div className='mt-2'>
                            <div className="mb-3 text-start">
                                <label className="form-label ">Exercise Name</label>
                                <input type="text" name="name" value={fields.name} onChange={handleChange} className="form-control common-input" placeholder="Enter here" />
                                {errors.name && <span className="err_msg">{errors.name}</span>}

                            </div>

                        </div>
                        <div
                            className="mb-3 text-center position-relative submit-head w-100 mt-4"
                        >
                            <button
                                onClick={postApi}
                                type="submit"
                                className="btn delete-btn w-100"
                            >
                                Submit{" "}
                            </button>

                        </div>
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    )
}

export default Excersice;