const TITLES = {
  DASHBOARD: 'Dashoard | FitX Pro',
    LEADS: 'Leads | FitX Pro',
    ADD_LEADS :'Add Leads | FitX Pro',
    ADD_CUSTOMER :'Add Customer | FitX Pro',
    EDIT_LEAD:"Edit Lead | FitX Pro",
    CUSTOMER :"Customers | FitX Pro",
    STAFF :"Staff | FitX Pro",
    ADD_STAFF :'Add Staff | FitX Pro',
    WORKOUT :"Workout | FitX Pro",
    WORKOUT_DETAIL :"Workout Detail | FitX Pro",
    ADD_WORKOUT :'Add Workout | FitX Pro',
    EXCERCISE :"Excercises | FitX Pro",
    EDIT_WORKOUT:'Edit Workout | FitX Pro',
    LOGIN :"Login | FitX Pro",
  };
 export default TITLES