import React, { useEffect, useState, useRef } from 'react'
import "../../../assets/style/addWorkout.css"
import uploadFileToS3 from '../../../Helper/fileUpload';
import ERR_MESSAGE from '../../../Helper/error-helper';
import { ADD, ADDBRANCH, REMOVE } from '../../../Middleware/images';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Middleware/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoader from '../../../Loader/react-loader';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';

const AddWorkout = () => {
    const navigate = useNavigate()
    const [excerciseList, setExcerciseList] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [fileUrl, setFileUrl] = useState("");
    const [name, setname] = useState("");
    const [workoutId, setWorkoutId] = useState(0)
    const previousWorkoutIdRef = useRef(0);
    const [activity_level, setActivityLevel] = useState("");
    const [activeTab, setActiveTab] = useState('day1');
    const [shouldCallApi, setShouldCallApi] = useState({
        day1: true,
        day2: true,
        day3: true,
        day4: true,
        day5: true,
        day6: true
    });

    const [exerciseDetailsDay1, setExerciseDetailsDay1] = useState([{ exercise: '', sets: '', reps: '', duration: '' }]);
    const [exerciseDetailsDay2, setExerciseDetailsDay2] = useState([{ exercise: '', sets: '', reps: '', duration: '' }]);
    const [exerciseDetailsDay3, setExerciseDetailsDay3] = useState([{ exercise: '', sets: '', reps: '', duration: '' }]);
    const [exerciseDetailsDay4, setExerciseDetailsDay4] = useState([{ exercise: '', sets: '', reps: '', duration: '' }]);
    const [exerciseDetailsDay5, setExerciseDetailsDay5] = useState([{ exercise: '', sets: '', reps: '', duration: '' }]);
    const [exerciseDetailsDay6, setExerciseDetailsDay6] = useState([{ exercise: '', sets: '', reps: '', duration: '' }]);

    const handleValidation = () => {
        let formIsValid = true;
        let newErrors = {};
        if (!imageUrl) {
            formIsValid = false;
            newErrors = { ...newErrors, imageUrl: ERR_MESSAGE.EMPTY_IMAGE };
        }
        if (!activity_level) {
            formIsValid = false;
            newErrors = { ...newErrors, activity_level: ERR_MESSAGE.EMPTY_ACTIVITY };
        }
        const activeExerciseDetails = getExerciseDetailsForDay(activeTab);
        const firstExercise = activeExerciseDetails[0];

        if (!firstExercise.exercise) {
            formIsValid = false;
            newErrors[`exercise${activeTab}0`] = ERR_MESSAGE.EMPTY_EXCERCISE;
        }
        if (!firstExercise.sets || firstExercise.sets.trim() === '') {
            formIsValid = false;
            newErrors[`sets${activeTab}0`] = ERR_MESSAGE.EMPTY_SETS;
        } else if (!/^[0-9]+$/.test(firstExercise.sets)) {
            formIsValid = false;
            newErrors[`sets${activeTab}0`] = ERR_MESSAGE.INVALID_SETS;
        }
        if (!firstExercise.reps || firstExercise.reps.trim() === '') {
            formIsValid = false;
            newErrors[`reps${activeTab}0`] = ERR_MESSAGE.EMPTY_REPS;
        } else if (!/^[0-9]+$/.test(firstExercise.reps)) {
            formIsValid = false;
            newErrors[`reps${activeTab}0`] = ERR_MESSAGE.INVALID_REPS;
        }
        if (!firstExercise.duration || firstExercise.duration.trim() === '') {
            formIsValid = false;
            newErrors[`duration${activeTab}0`] = ERR_MESSAGE.EMPTY_DURATION;
        } else if (!/^[0-9]+$/.test(firstExercise.duration)) {
            formIsValid = false;
            newErrors[`duration${activeTab}0`] = ERR_MESSAGE.INVALID_DURATION;
        }

        if (!name || name.trim() === '') {
            formIsValid = false;
            newErrors = { ...newErrors, name: ERR_MESSAGE.EMPTY_TITLE };
         }
         else if (/[^a-zA-Z\s]+/.test(name)) {
            formIsValid = false;
            newErrors = { ...newErrors, name: ERR_MESSAGE.INVALID_TITLE };
        }
        setErrors(newErrors);
        return formIsValid;
    };



    const handleChange = (e, index, day) => {
        setErrors({});
        const { name, value } = e.target;
        const regex = /^[0-9\b]+$/; // Regular expression to match digits
    
        // Check if the input value matches the regex pattern
        if (value === '' || regex.test(value)) {
            switch (day) {
                case 'day1':
                    setExerciseDetailsDay1(prevExerciseDetails => {
                        const updatedExerciseDetails = [...prevExerciseDetails];
                        updatedExerciseDetails[index] = {
                            ...updatedExerciseDetails[index],
                            [name]: value
                        };
                        return updatedExerciseDetails;
                    });
                    break;
                case 'day2':
                    setExerciseDetailsDay2(prevExerciseDetails => {
                        const updatedExerciseDetails = [...prevExerciseDetails];
                        updatedExerciseDetails[index] = {
                            ...updatedExerciseDetails[index],
                            [name]: value
                        };
                        return updatedExerciseDetails;
                    });
                    break;
                case 'day3':
                    setExerciseDetailsDay3(prevExerciseDetails => {
                        const updatedExerciseDetails = [...prevExerciseDetails];
                        updatedExerciseDetails[index] = {
                            ...updatedExerciseDetails[index],
                            [name]: value
                        };
                        return updatedExerciseDetails;
                    });
                    break;
                case 'day4':
                    setExerciseDetailsDay4(prevExerciseDetails => {
                        const updatedExerciseDetails = [...prevExerciseDetails];
                        updatedExerciseDetails[index] = {
                            ...updatedExerciseDetails[index],
                            [name]: value
                        };
                        return updatedExerciseDetails;
                    });
                    break;
                case 'day5':
                    setExerciseDetailsDay5(prevExerciseDetails => {
                        const updatedExerciseDetails = [...prevExerciseDetails];
                        updatedExerciseDetails[index] = {
                            ...updatedExerciseDetails[index],
                            [name]: value
                        };
                        return updatedExerciseDetails;
                    });
                    break;
                case 'day6':
                    setExerciseDetailsDay6(prevExerciseDetails => {
                        const updatedExerciseDetails = [...prevExerciseDetails];
                        updatedExerciseDetails[index] = {
                            ...updatedExerciseDetails[index],
                            [name]: value
                        };
                        return updatedExerciseDetails;
                    });
                    break;
                default:
                    break;
            }
        } else {
            return;
        }
    };
    

    let previousTab = '';
    const switchTab = (tab) => {
        setErrors("");
        previousTab = activeTab;
        const activeExerciseDetails = getExerciseDetailsForDay(previousTab);
    
        const isAnyRowFilled = activeExerciseDetails.some(item => {
            const isExerciseValid = !!item.exercise;
            const isSetsValid = !!item.sets && /^[0-9]+$/.test(item.sets);
            const isRepsValid = !!item.reps && /^[0-9]+$/.test(item.reps);
            const isDurationValid = !!item.duration && /^[0-9]+$/.test(item.duration);
            return isExerciseValid && isSetsValid && isRepsValid && isDurationValid;
        });
   
        const exerciseFieldsValid = activeExerciseDetails.every(item => {
            const isExerciseValid = !!item.exercise;
            const isSetsValid = !!item.sets && /^[0-9]+$/.test(item.sets);
            const isRepsValid = !!item.reps && /^[0-9]+$/.test(item.reps);
            const isDurationValid = !!item.duration && /^[:0-9]+$/.test(item.duration);
            return isExerciseValid && isSetsValid && isRepsValid && isDurationValid;
        });
        if (tab < previousTab) {
            setActiveTab(tab);
        } 
        else if (
            shouldCallApi[previousTab] &&
            activity_level &&
            imageUrl &&
            name &&
            (isAnyRowFilled || exerciseFieldsValid)
        ) {
            setActiveTab(tab);
            postApi(previousTab);
        } else {
            setActiveTab(tab);
        }
    };
    
    
    useEffect(() => {
        let excer1 = JSON.parse(localStorage.getItem("exerciseDetailsDay1")) || []
        const extraExercise1 = { exercise: '', sets: '', reps: '', duration: '' };
        excer1 = [...excer1, extraExercise1];
        setExerciseDetailsDay1(excer1);
        let excer2 = JSON.parse(localStorage.getItem("exerciseDetailsDay2")) || []
        const extraExercise2 = { exercise: '', sets: '', reps: '', duration: '' };
        excer2 = [...excer2, extraExercise2];
        setExerciseDetailsDay2(excer2);

        let excer3 = JSON.parse(localStorage.getItem("exerciseDetailsDay3")) || []
        const extraExercise3 = { exercise: '', sets: '', reps: '', duration: '' };
        excer3 = [...excer3, extraExercise3];
        setExerciseDetailsDay3(excer3);

        let excer4 = JSON.parse(localStorage.getItem("exerciseDetailsDay4")) || []
        const extraExercise4 = { exercise: '', sets: '', reps: '', duration: '' };
        excer4 = [...excer4, extraExercise4];
        setExerciseDetailsDay4(excer4);

        let excer5 = JSON.parse(localStorage.getItem("exerciseDetailsDay5")) || []
        const extraExercise5 = { exercise: '', sets: '', reps: '', duration: '' };
        excer5 = [...excer5, extraExercise5];
        setExerciseDetailsDay5(excer5);

        let excer6 = JSON.parse(localStorage.getItem("exerciseDetailsDay6")) || []
        const extraExercise6 = { exercise: '', sets: '', reps: '', duration: '' };
        excer6 = [...excer6, extraExercise6];
        setExerciseDetailsDay6(excer6);
    }, []);
    const addExerciseRow = (day) => {
        const newExercise = { exercise: '', sets: '', reps: '', duration: '' };
        switch (day) {
            case 'day1':
                setExerciseDetailsDay1(prevExerciseDetails => [...prevExerciseDetails, newExercise]);
                localStorage.setItem("exerciseDetailsDay1", JSON.stringify(exerciseDetailsDay1));
                break;
            case 'day2':
                setExerciseDetailsDay2(prevExerciseDetails => [...prevExerciseDetails, newExercise]);
                localStorage.setItem("exerciseDetailsDay2", JSON.stringify(exerciseDetailsDay2));
                break;
            case 'day3':
                setExerciseDetailsDay3(prevExerciseDetails => [...prevExerciseDetails, newExercise]);
                localStorage.setItem("exerciseDetailsDay3", JSON.stringify(exerciseDetailsDay3));
                break;
            case 'day4':
                setExerciseDetailsDay4(prevExerciseDetails => [...prevExerciseDetails, newExercise]);
                localStorage.setItem("exerciseDetailsDay4", JSON.stringify(exerciseDetailsDay4));

                break;
            case 'day5':
                setExerciseDetailsDay5(prevExerciseDetails => [...prevExerciseDetails, newExercise]);
                localStorage.setItem("exerciseDetailsDay5", JSON.stringify(exerciseDetailsDay5));

                break;
            case 'day6':
                setExerciseDetailsDay6(prevExerciseDetails => [...prevExerciseDetails, newExercise]);
                localStorage.setItem("exerciseDetailsDay6", JSON.stringify(exerciseDetailsDay6));

                break;
            default:
                break;
        }
    };

    const removeExerciseRow = (index, day) => {
        let updatedExerciseDetails;
        switch (day) {
            case 'day1':
                updatedExerciseDetails = exerciseDetailsDay1.filter((_, i) => i !== index);
                setExerciseDetailsDay1(updatedExerciseDetails);
                localStorage.setItem("exerciseDetailsDay1", JSON.stringify(updatedExerciseDetails.slice(0, -1)));
                break;
            case 'day2':
                updatedExerciseDetails = exerciseDetailsDay2.filter((_, i) => i !== index);
                setExerciseDetailsDay2(updatedExerciseDetails);
                localStorage.setItem("exerciseDetailsDay2", JSON.stringify(updatedExerciseDetails.slice(0, -1)));
                break;
            case 'day3':
                updatedExerciseDetails = exerciseDetailsDay3.filter((_, i) => i !== index);
                setExerciseDetailsDay3(updatedExerciseDetails);
                localStorage.setItem("exerciseDetailsDay3", JSON.stringify(updatedExerciseDetails.slice(0, -1)));
                break;
            case 'day4':
                updatedExerciseDetails = exerciseDetailsDay4.filter((_, i) => i !== index);
                setExerciseDetailsDay4(updatedExerciseDetails);
                localStorage.setItem("exerciseDetailsDay4", JSON.stringify(updatedExerciseDetails.slice(0, -1)));
                break;
            case 'day5':
                updatedExerciseDetails = exerciseDetailsDay5.filter((_, i) => i !== index);
                setExerciseDetailsDay5(updatedExerciseDetails);
                localStorage.setItem("exerciseDetailsDay5", JSON.stringify(updatedExerciseDetails.slice(0, -1)));
                break;
            case 'day6':
                updatedExerciseDetails = exerciseDetailsDay6.filter((_, i) => i !== index);
                setExerciseDetailsDay6(updatedExerciseDetails);
                localStorage.setItem("exerciseDetailsDay6", JSON.stringify(updatedExerciseDetails.slice(0, -1)));
                break;
            default:
                break;
        }
    };
    const renderExerciseRowsForDay = (day) => {
        const exerciseDetails = getExerciseDetailsForDay(day);
        return exerciseDetails?.map((item, index) => (
            <div className="row align-items-center" key={index}>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-3 ">
                    <div className="mb-4">
                        <label className="form-label">Exercise</label>
                        <select
                            name="exercise"
                            value={item.exercise}
                            onChange={(e) => handleChange(e, index, day)}
                            className="form-select common-input"
                        >
                            <option value="" selected>Select</option>
                            {
                                excerciseList?.map((item) => {
                                    return (
                                        <>
                                            <option key={item.id} value={item.id}>{item.exercise_name}</option>
                                        </>
                                    )

                                })
                            }
                        </select>
                        {errors[`exercise${day}${index}`] && <span className="err_msg">{errors[`exercise${day}${index}`]}</span>}
                    </div>
                </div>                <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                    <div className="mb-4">
                        <label className="form-label">Sets</label>
                        <input
                            type="text"
                            name="sets"
                            value={item.sets}
                            onChange={(e) => handleChange(e, index, day)}
                            className="form-control common-input"
                            placeholder="Enter here"
                        />

                        {errors[`sets${day}${index}`] && <span className="err_msg">{errors[`sets${day}${index}`]}</span>}

                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                    <div className="mb-4">
                        <label className="form-label">Reps</label>
                        <input
                            type="text"
                            name="reps"
                            value={item.reps}
                            onChange={(e) => handleChange(e, index, day)}
                            className="form-control common-input"
                            placeholder="Enter here"
                        />

                        {errors[`reps${day}${index}`] && <span className="err_msg">{errors[`reps${day}${index}`]}</span>}

                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-2">
                    <div className="mb-4">
                        <label className="form-label">Duration(min)</label>
                        <input
                            type="text"
                            name="duration"
                            value={item.duration}
                            onChange={(e) => handleChange(e, index, day)}
                            className="form-control common-input"
                            placeholder="Enter here"
                        />

                        {errors[`duration${day}${index}`] && <span className="err_msg">{errors[`duration${day}${index}`]}</span>}

                    </div>
                </div>
                {index === 0 && (
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-1 ps-4">
                        <div className="mt-1">
                            <button className="addButton" onClick={() => addExerciseRow(day)}>
                                <img src={ADD} alt="Add" />
                            </button>
                        </div>
                    </div>
                )}
                <div className="col-12 col-sm-6 col-lg-6 col-xl-1 ps-4">
                    {index !== 0 && (
                        <div className="mt-1">
                            <button className="addButton" onClick={() => removeExerciseRow(index, day)}>
                                <img src={REMOVE} alt="Remove" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        ));
    }
 
    const getExerciseDetailsForDay = (day) => {
        switch (day) {
            case 'day1':
                return exerciseDetailsDay1;
            case 'day2':
                return exerciseDetailsDay2;
            case 'day3':
                return exerciseDetailsDay3;
            case 'day4':
                return exerciseDetailsDay4;
            case 'day5':
                return exerciseDetailsDay5;
            case 'day6':
                return exerciseDetailsDay6;

            default:
                return exerciseDetailsDay1;
        }
    };

    async function getApi() {
        setLoading(true)
        let result = await Services.getService("workout/all-exercise");
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseList(result?.body?.data);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
     }

    useEffect(() => {
        getApi()
    }, [])

    const extractNumericPart = (dayString) => {
        const numericPart = dayString.match(/\d+/);
        return numericPart ? numericPart[0] : null;
    };

    async function postApi(day) {
        setLoading(true);
        const exerciseDetails = getExerciseDetailsForDay(day);
        const allExerciseData = [];
        exerciseDetails.forEach((exercise) => {
            const exerciseData = {
                exercise_id: parseInt(exercise.exercise),
                days: extractNumericPart(day),
                sets: parseInt(exercise.sets),
                reps: parseInt(exercise.reps),
                duration: parseInt(exercise.duration),
            };
            allExerciseData.push(exerciseData);
        });

        const data = {
            "exercises": allExerciseData,
            "workout_id": workoutId,
            "workout_name": name,
            "activity_level": activity_level,
            "image_url": fileUrl,
        };
        try {
            const result = await Services.postService("workout/add-workout", JSON.stringify(data));
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                const newWorkoutId = result?.body?.workout_id || 0;
                setWorkoutId(newWorkoutId !== 0 ? newWorkoutId : workoutId);
                previousWorkoutIdRef.current = newWorkoutId !== 0 ? newWorkoutId : previousWorkoutIdRef.current;
                if (day == "day6") {
                    localStorage.setItem("message", result.message)
                    navigate(ROUTES.WORKOUT);
                }
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
        catch (error) {
            setLoading(false);
        }
    }


    const fileChange = (e) => {
        setErrors({});
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("image", file);
                const imageURL = URL.createObjectURL(file);
                setImageUrl(imageURL);
                fileUpload(file)
            }
        }
    };

    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
                setFileUrl(fileUrl)
            }
        }
        catch (error) {
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            const day = activeTab;
            setShouldCallApi(prevState => ({
                ...prevState,
                [day]: false
            }));
            postApi(activeTab)
        }
    }
    return (
        <>
            <Helmet>
                <title>{TITLES.ADD_WORKOUT}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <div className='container-fluid my-4 pl-2'>
                <h5 className='mb-0 fw-600'>Workouts / Add New Workout</h5>
            </div>
            <section className='bg-white p-2 m-3 main-spacing mt-3 main-height'>
                <div className="container-fluid">
                    <div className="row pb-4 pt-4 pt-lg-0">
                        <div className='col-lg-3'>
                            <div className='name-wrapper'>
                                <form>
                                    <div className="col-12">
                                        <div className="mb-4">
                                            <label className="form-label">Workout Name</label>
                                            <input type='text' name="name" value={name} placeholder="Enter workout name" onChange={(e) => { setErrors({}); setname(e.target.value) }} className="form-control common-input" />
                                            {errors.name && <span className="err_msg">{errors.name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-4">
                                            <label className="form-label">Workout Activity Level</label>
                                            <select value={activity_level} onChange={(e) => setActivityLevel(e.target.value)}
                                                className="form-select common-input"
                                            >                                                                    <option value="">Select</option>
                                                <option value="Rokie">Rokie</option>
                                                <option value="Beginner">Beginner</option>
                                                <option value="Intermediate">Intermediate</option>
                                                <option value="Advance ">Advance</option>
                                                <option value="True Beast">True Beast</option>

                                            </select>
                                            {errors.activity_level && <span className="err_msg">{errors.activity_level}</span>}

                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label className="form-label mb-0"> Image</label>
                                        <div className="position-relative mb-3">

                                            <div className="profile-pic-change ">
                                                <div className="profile-upper-pic workout-upper-img">
                                                    <img src={imageUrl == "" ? ADDBRANCH : imageUrl} className='add-wrokoutimg' alt='' />
                                                    {errors.imageUrl && <span className="err_msg">{errors.imageUrl}</span>}

                                                    {/* <p className="f14-size text-black fw-600">{!ADDname ? "" : "Upload Image"}</p> */}

                                                </div>
                                            </div>
                                            <input type="file" name='imageUrl' onChange={fileChange} className="input-profile" id="image" accept=".jpg, .jpeg, .png" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-9  borderLeft'>
                            <div className='workoutDays-tabs'>
                                <nav>
                                    <div class="nav nav-tabs mb-2" id="nav-tab" role="tablist">
                                        <button className={`nav-link ${activeTab === 'day1' ? 'active' : ''}`}
                                            onClick={() => switchTab('day1')} id="nav-day1-tab" data-bs-toggle="tab" data-bs-target="#nav-day1" type="button" role="tab" aria-controls="nav-day1" aria-selected="true">Day 1</button>
                                        <button className={`nav-link ${activeTab === 'day2' ? 'active' : ''}`}
                                            onClick={() => switchTab('day2')} id="nav-day2-tab" data-bs-toggle="tab" data-bs-target="#nav-day2" type="button" role="tab" aria-controls="nav-day1" aria-selected="false">Day 2</button>
                                        <button className={`nav-link ${activeTab === 'day3' ? 'active' : ''}`}
                                            onClick={() => switchTab('day3')} id="nav-day3-tab" data-bs-toggle="tab" data-bs-target="#nav-day3" type="button" role="tab" aria-controls="nav-day3" aria-selected="false">Day 3</button>
                                        <button className={`nav-link ${activeTab === 'day4' ? 'active' : ''}`}
                                            onClick={() => switchTab('day4')} id="nav-day4-tab" data-bs-toggle="tab" data-bs-target="#nav-day4" type="button" role="tab" aria-controls="nav-day4" aria-selected="false">Day 4</button>
                                        <button className={`nav-link ${activeTab === 'day5' ? 'active' : ''}`}
                                            onClick={() => switchTab('day5')} id="nav-day5-tab" data-bs-toggle="tab" data-bs-target="#nav-day5" type="button" role="tab" aria-controls="nav-day5" aria-selected="false">Day 5</button>
                                        <button className={`nav-link ${activeTab === 'day6' ? 'active' : ''}`}
                                            onClick={() => switchTab('day6')} id="nav-day6-tab" data-bs-toggle="tab" data-bs-target="#nav-day6" type="button" role="tab" aria-controls="nav-day6" aria-selected="false">Day 6</button>
                                    </div>
                                </nav>
                                <div class="tab-content pt-2 px-2 " id="nav-tabContent">
                                    <div class="tab-pane fade active show" id="nav-day1" role="tabpanel" aria-labelledby="nav-day1-tab">
                                        <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                                        {renderExerciseRowsForDay('day1')}
                                    </div>
                                    <div class="tab-pane fade" id="nav-day2" role="tabpanel" aria-labelledby="nav-day2-tab">
                                        <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                                        {renderExerciseRowsForDay('day2')}
                                    </div>
                                    <div class="tab-pane fade" id="nav-day3" role="tabpanel" aria-labelledby="nav-day3-tab">
                                        <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                                        {renderExerciseRowsForDay('day3')}
                                    </div>
                                    <div class="tab-pane fade" id="nav-day4" role="tabpanel" aria-labelledby="nav-day4-tab">
                                        <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                                        {renderExerciseRowsForDay('day4')}
                                    </div>
                                    <div class="tab-pane fade" id="nav-day5" role="tabpanel" aria-labelledby="nav-day5-tab">
                                        <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                                        {renderExerciseRowsForDay('day5')}
                                    </div>
                                    <div class="tab-pane fade" id="nav-day6" role="tabpanel" aria-labelledby="nav-day6-tab">
                                        <h2 className='fw-700 mb-3 textColor'>Excersices</h2>
                                        {renderExerciseRowsForDay('day6')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-end'>
                        <button type="button" class=" next-btn" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}




export default AddWorkout