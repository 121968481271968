import React from 'react'
import { NO_DATA_FOUND } from '../../Middleware/images'

const NotFound = () => {
    return (
        <div className='main-inner-content'>
            <div className='no-data-found'>
                <img src={NO_DATA_FOUND} className='img-fluid' alt=''/>
            </div>
        </div>
    )
}
export default NotFound
