import React from 'react';
function calculatePaginationRange(currentPage, totalPages) {
    const maxPagesToShow = 4;
    const halfMaxPages = Math.floor(maxPagesToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
    } else if (currentPage <= halfMaxPages) {
        startPage = 1;
        endPage = maxPagesToShow;
    } else if (currentPage + halfMaxPages >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
    } else {
        startPage = currentPage - halfMaxPages;
        endPage = currentPage + halfMaxPages;
    }


    return { startPage, endPage };
}
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const { startPage, endPage } = calculatePaginationRange(currentPage, totalPages);

    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    return (
        <>
            {currentPage > 1 && (
                <li style={{ cursor: 'pointer' }} className="page-item">
                    <a className="page-prev-btn" href="#" onClick={() => onPageChange(currentPage - 1)}>
                        Previous
                    </a>
                </li>
            )}

            {startPage > 1 && (
                <li className="page-item disabled" style={{ cursor: 'pointer' }}>
                    <span className="page-count" onClick={() => onPageChange(1)}>1</span>
                </li>
            )}
            {startPage > 2 && (
                <li className="page-item disabled">
                    <span className="page-count">...</span>
                </li>
            )}

            {pageNumbers.map((pageNumber) => (
                <li style={{ cursor: 'pointer' }} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`} key={pageNumber}>
                    <a className={`page-count ${pageNumber === currentPage ? 'active' : ''} me-2`} onClick={() => onPageChange(pageNumber)}>
                        {pageNumber}
                    </a>
                </li>
            ))}

            {endPage < totalPages - 1 && (
                <li className="page-item disabled">
                    <span className="page-count">...</span>
                </li>
            )}

            {endPage < totalPages && (
                <li className="page-item disabled" style={{ cursor: 'pointer' }}>
                    <span className="page-count" onClick={() => onPageChange(totalPages)}>{totalPages}</span>
                </li>
            )}

            {currentPage < totalPages && (
                <li style={{ cursor: 'pointer' }} className="page-item">
                    <a className="page-next-btn"  onClick={() => onPageChange(currentPage + 1)}>
                        Next
                    </a>
                </li>
            )}
        </>
    );
};

export default Pagination;
