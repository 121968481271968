import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function DatePickerValue(props) {
    const handleChange = (newValue) => {
        props.setErrors("");
        if (!newValue) {
            props?.setFn("");
            return;
        }
        const inputDate = newValue.$d instanceof Date ? newValue.$d : newValue;
        const formattedDate = dayjs(inputDate)?.format("YYYY-MM-DD");

        if (formattedDate === "Invalid Date") {
            props?.setFn("");
        } else {
            props?.setFn(formattedDate);
        }
    };

    const valueToDisplay = props.value ? dayjs(props.value) : null;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                format="DD/MM/YYYY"
                disabled={props.notAnswered1 || props.notInterested1 || props.callResponse1 || props.interested1 || props.notAnswered || props.notInterested || props.callResponse || props.interested || (props.name === "planEnd" && !props.endDate) || (props.name == "planEndFreeze" && !props.endFreezeDate || props.status == 3)}
                value={valueToDisplay}
                onChange={(newValue) => handleChange(newValue)}
                className={props.notAnswered1 || props.notInterested1 || props.callResponse1 || props.interested1 || props.notAnswered || props.notInterested || props.callResponse || props.interested || (props.name === "planEnd" && !props.endDate) || props.status == 3 ? "disabledbtn-custom" : ""}
                readOnly={((props.name === "planEnd" && props.endDate) || (props.name == "planEndFreeze" && props.endFreezeDate))}
            />
        </LocalizationProvider>
    );
}
