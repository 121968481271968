import React, { Component, useEffect, useState } from 'react'
import { WRITE_CONTENT } from '../Middleware/images'
import Pagination from './pagination';
import moment from "moment";

function Freeze(props) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    {
                        props?.bill?.length == 0 ? <>
                            <div className="no-data-content text-center">
                                <img src={WRITE_CONTENT} className="img-fluid" />
                                <p className="no-data-text fw-500 mt-2">No History Yet</p>
                            </div>
                        </> : <>
                            <div className={`leads-table-responsive  height-tooltiptable member-table-top`}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="leads-table-head">Freeze  date</th>
                                            <th scope="col" className="leads-table-head">Plan Start date</th>
                                            <th scope="col" className="leads-table-head">Plan End date</th>
                                            <th scope="col" className="leads-table-head">Freeze Start date</th>
                                            <th scope="col" className="leads-table-head">Freeze End date</th>
                                            <th scope="col" className="leads-table-head">Freeze Days</th>
                                            <th scope="col" className="leads-table-head">Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props?.bill?.map((item, index) => {
                                                const isLastItem = index == props.bill.length - 1;
                                                return (
                                                    <tr className={props?.bill.length > 2 && isLastItem && isHovered ? "last-row" : ""} onMouseEnter={() => setIsHovered(true)}
                                                        onMouseLeave={() => setIsHovered(false)}  >
                                                        <td className="leads-table-data text-start">{moment(item.created_at).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{moment(item.membership_details.start_date).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{moment(item.membership_details.end_date).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data ">
                                                            {moment(item.start_date).format('Do MMM, YYYY')}
                                                        </td>
                                                        <td className="leads-table-data"> {moment(item.end_date).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{item.freeze_days}</td>
                                                        <td className="leads-table-data">
                                                            <div className='tooltip-container' >
                                                                {item?.reason?.length > 50 ? item.reason.substring(0, 50) + "...." : item.reason}
                                                                <span className={item?.reason?.length > 50 ? "tooltip-text ":"tooltip-text tooltip-text-width"}>{item.reason}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>

                                </table>
                            </div>
                            {
                                props?.bill?.length > 0 && <>
                                    <div className="row pt-3">
                                        <div className="col-6">
                                            <a href=""><span className="page-entry">
                                                {
                                                    props.total1 === 1 ? <>
                                                        Showing {props.total1} entry
                                                    </> :
                                                        <>
                                                            Showing {props.startIndex} to {props.endIndex1} of {props.total1} entries
                                                        </>
                                                }
                                            </span>
                                            </a>
                                        </div>
                                        <div className="col-6">
                                            <nav aria-label="Page">
                                                <ul className="pagination d-flex justify-content-end">
                                                    <Pagination currentPage={props.currentPage} totalPages={props.totalPages1} onPageChange={props.handlePageChange} />
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Freeze