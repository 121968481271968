import React, { useState } from 'react'
import { MODAL_CROSS, IMPORT_ICON, MANUAL_ICON, PREV_ICON, NEXT_ICON } from '../Middleware/images';
import { useNavigate } from 'react-router-dom';
import "../assets/style/modal.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ROUTES from '../Middleware/routes';

const Modals = (props) => {
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        customer: "",
        platform: "",
        subject: "",
        message: ""
 })
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFields({
            ...fields,
            [name]: value
        })
    }
    const OwlCarouselEnt = {
        loop: true,
        margin: 30,
        dots: true,
        nav: true,
        navText: [`<div className='nav-button custom-nav-btn'><img src=${PREV_ICON}></div>`, `<div className='nav-button custom-nav-btn'><img src=${NEXT_ICON}></div>`],
        items: 1,
        dots: false,
    }
    return (
        <section className="view-file">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="modal fade" id="View" tabIndex="-1" aria-labelledby="ViewLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="contain">
                                            {/* <OwlCarousel id="owl-carousel" className="owl-carousel owl-theme" {...OwlCarouselEnt}>
                                                {props?.images?.map((item) =>
                                                    <div className="item">
                                                        <div className="item-content">
                                                            <img src={ADDBRANCH} alt="" className="img-fluid" />
                                                            
                                                        </div>
                                                    </div>
                                                )} */}

                                                {/* <div className="item">
                                                    <div className="item-content">
                                                        <img src={props.selectedImageUrl} alt="" className="img-fluid" />
                                                    </div>
                                                </div> */}
                                                
                                            {/* </OwlCarousel> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!--Add Customer Modal-- > */}
                        <div className="modal fade" id="Customer" tabIndex="-1" aria-labelledby="CustomerLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header text-end justify-content-end p-2" style={{ borderbottom: "0px" }}>
                                        <a data-bs-dismiss="modal" aria-label="Close"><img src={MODAL_CROSS} className="img-fluid" /></a>
                                    </div>
                                    <div className="modal-body" style={{ padding: "0px 1rem 1rem 1rem" }}>
                                        <div className="customer-modal text-center pb-3">
                                            <h2 className="customer-modal-text fw-600">Add Customer</h2>
                                        </div>
                                        <div className="file-select position-relative mb-4">
                                            <input type="file" className="upload-file" />
                                            <div className="file-img d-flex m-auto justify-content-center align-items-center">
                                                <div className="text-center">
                                                    <img src={IMPORT_ICON} className="img-fluid" />
                                                    <p className="file-text fw-400 text-center py-2">Import customer</p>
                                                    <p className="file-format-text fw-400 text-center pb-3">Please upload csv & xlsx</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="file-select position-relative">
                                            <div onClick={() => { navigate(ROUTES.ADD_CUSTOMER) }} className="file-img d-flex m-auto justify-content-center align-items-center" data-bs-dismiss="modal" aria-label="Close">
                                                <div className="text-center">
                                                    <img src={MANUAL_ICON} className="img-fluid" />
                                                    <p className="file-text fw-400 text-center py-2">Enter Manual</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!--send message Modal-- > */}
                        <div className="modal fade" id="message" tabIndex="-1" aria-labelledby="messageLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header text-end justify-content-end p-2" style={{ borderbottom: "0px" }}>
                                        <a data-bs-dismiss="modal" aria-label="Close"><img src={MODAL_CROSS} className="img-fluid" /></a>
                                    </div>
                                    <div className="modal-body" style={{ padding: "0px 1rem 1rem 1rem" }}>
                                        <div className="customer-modal text-center pb-3">
                                            <h2 className="customer-modal-text fw-600">Send Message</h2>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Customer</label>
                                                        <select name='customer' value={fields.customer} onChange={handleChange} className="form-select common-input">
                                                            <option value = "Select">Select</option>
                                                            <option value="1">Adit</option>
                                                            <option value="2">Vikas</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Platform</label>
                                                        <select name='platform' value={fields.platform} onChange={handleChange} className="form-select common-input">
                                                            <option value="">Select</option>
                                                            <option value="1">WhatsApp</option>
                                                            <option value="2">Instagram</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Subject</label>
                                                        <input type="text" name='subject' value={fields.subject} onChange={handleChange} className="form-control common-input" placeholder="Enter Subject" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Message</label>
                                                        <textarea className="form-control common-input remark-textarea" name='message' value={fields.message} onChange={handleChange} rows="5" placeholder="Enter Message"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-end align-self-end">
                                                    <button type="submit" className="next-submit-btn">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Modals;
