import React, { useState } from 'react'
import { PROFILE } from '../Middleware/images'
import { Clock } from '@mui/x-date-pickers/TimeClock/Clock'

function Client(props) {
    return (
        <div className="row">
            {
                props?.client?.length == 0 && <p className='text-center my-5 f18 fw-500'>No references client available </p>

            }
            <div className="training-card">
                {
                    props?.client.map((item) => (
                        <div className="trainig-card-head-client text-center mb-4 py-4 px-3">
                            <img src={item.item} className="training-demo-client " />
                            <div className="trainig-card-content text-center">
                                <h4 className="pt-2 client-training-head">{item.name}</h4>
                                <p className='py-2 client-training-text fw-400'>member since 2023</p>
                                <p className="d-flex align-items-center justify-content-center">
                                        {item.status == 1 ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}
                                        <span>{item.status == 1 ? "Active" : "Inactive"}</span>
                                </p>
                            </div>
                        </div>
                    ))
                }


            </div>


        </div>
    )
}

export default Client