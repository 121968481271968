import React from 'react'
import { BILLING, DELETE_ICON, DOWNLOAD, PRINT } from '../Middleware/images'
import moment from "moment";
import Pagination from './pagination';
import Services from '../Helper/services';
import DEVELOPMENT_CONFIG from '../Helper/config';
import ReactToPrint from 'react-to-print';
function Bills(props) {

    // async function getInvoice(id) {
    //     props.setLoading(true)
    //     let result = await Services.getService(`admin/create-invoice?id=${id}`);
    //     if (result.code === DEVELOPMENT_CONFIG.statusCode) {
    //         props.setLoading(false);
    //         console.log(result.data, "Invoice Downloaded")
    //     }
    //     else {
    //         props.setLoading(false);
    //         console.log("error in invoice downloaing")
    //     }
    // }
 
    
   
    return (
        <>
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    {
                        props?.bill?.length == 0 ? <>
                            <div className="no-data-content text-center">
                                <img src={BILLING} className="img-fluid" />
                            </div>
                        </> : <>
                            <div className="table-responsive leads-table-responsive member-table-top">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="leads-table-head">Purchase date</th>
                                            <th scope="col" className="leads-table-head">Bill No</th>

                                            <th scope="col" className="leads-table-head">Start  date</th>
                                            <th scope="col" className="leads-table-head">End date</th>
                                            <th scope="col" className="leads-table-head">Base amount</th>
                                            <th scope="col" className="leads-table-head">discount</th>
                                            <th scope="col" className="leads-table-head">net amount</th>
                                            {/* <th scope="col" className="leads-table-head">paid amount</th> */}
                                            <th scope="col" className="leads-table-head">Balance Amount</th>
                                            <th scope="col" className="leads-table-head">Payment Mode</th>
                                            {/* <th scope="col" className="leads-table-head">Bill Status</th> */}
                                            <th scope="col" className="leads-table-head">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props?.bill?.map((item) => {
                                                const discountPercentage = ((item.base_amount - item.net_amount) / item.base_amount) * 100;
                                                const roundedDiscountPercentage = discountPercentage % 1 === 0 ? discountPercentage.toFixed(0) : discountPercentage.toFixed(2);
                                                return (
                                                    <tr key={item?.id}>
                                                        <td className="leads-table-data text-start">{moment(item.created_at).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{item?.bill_number}</td>
                                                        <td className="leads-table-data">{item?.membership_details?.start_date ? moment(item?.membership_details?.start_date).format('Do MMM, YYYY') : "-"}</td>
                                                        <td className="leads-table-data">{item?.membership_details?.end_date ? moment(item?.membership_details?.end_date).format('Do MMM, YYYY') : "-"}</td>
                                                        <td className="leads-table-data">{`₹${item?.base_amount}`}</td>
                                                        <td className="leads-table-data ">
                                                            {discountPercentage === 0 ? "-" : `${roundedDiscountPercentage}%`}
                                                        </td>
                                                        <td className="leads-table-data">{`₹${item?.net_amount}`}</td>
                                                        {/* <td className="leads-table-data">{`₹${item?.paid_amount}`}</td> */}
                                                        <td className="leads-table-data">{item?.balance_amount === "0" ? "₹0" : `₹${item?.balance_amount}`}</td>
                                                        <td className="leads-table-data">{item?.mode_of_payment}</td>
                                                        {/* <td className="leads-table-data">
                                                            <p className="d-flex align-items-center">
                                                                {item?.bill_status == "1" ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}
                                                                <span>{item?.bill_status == "1" ? "Paid" : item?.bill_status == "2" ? "Cancelled" : "Expired"}</span>
                                                            </p>
                                                        </td> */}
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center text-start align-items-center gap-3">
                                                                <img
                                                                    src={DELETE_ICON}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    onClick={() => props.handleBillOpen(item.id)}
                                                                />
                                                                {

                                                                    item.status != 3 && <img
                                                                        src={PRINT}
                                                                        style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                        // onClick={() => props.handleMembershipOpen(item.id)}
                                                                        alt=""
                                                                        className="img-fluid"
                                                                    />
                                                                }
                                                                <img
                                                                    src={DOWNLOAD}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    // onClick={() => getInvoice(item.id)}
                                                                />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>

                                </table>
                            </div>
                            {
                                props?.bill?.length > 0 && <>
                                    <div className="row pt-3">
                                        <div className="col-6">
                                            <a href=""><span className="page-entry">
                                                {
                                                    props.total1 === 1 ? <>
                                                        Showing {props.total1} entry
                                                    </> :
                                                        <>
                                                            Showing {props.startIndex} to {props.endIndex1} of {props.total1} entries
                                                        </>
                                                }
                                            </span>
                                            </a>
                                        </div>
                                        <div className="col-6">
                                            <nav aria-label="Page">
                                                <ul className="pagination d-flex justify-content-end">
                                                    <Pagination currentPage={props.currentPage} totalPages={props.totalPages1} onPageChange={props.handlePageChange} />
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }


                </div>
            </div>

        </>
    )
}

export default Bills