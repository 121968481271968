import "../assets/style/footer.css"
function Footer() {
    return(
        <>
         <footer className="footer-main mt-4">
                <div className="conatiner-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-head text-center">
                                <p className="footer-content">@ 2023 FitXPro. All Right Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;