import React, { useEffect, useState } from 'react'
import "../../../assets/style/workoutDetail.css"
import { ADDBRANCH,  ARROWRIGHT, CROSS_BTN, USER_PROFILE } from "../../../Middleware/images";
import ROUTES from '../../../Middleware/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
import ERR_MESSAGE from '../../../Helper/error-helper';
import uploadFileToS3 from '../../../Helper/fileUpload';
import ReactLoader from '../../../Loader/react-loader';
import Services from '../../../Helper/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
    height: 600,
    overflow: "hidden",
    overflowY: "scroll"
};

const WorkoutDetail = () => {
    const [excerciseList1, setExcerciseList1] = useState([]);
    const [userList, setUserList] = useState([])
    const location = useLocation()
    const Id = location.state.id
    const navigate = useNavigate()
    // const [url_type, setUrlType] = useState(null)
    const [errors, setErrors] = useState("");
    // const [imageUrl, setImageUrl] = useState("");
    const [workoutDetail, setWorkoutDetail] = useState([]);
    const [excerciseList, setExcerciseList] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');
    // const [fileUrl, setFileUrl] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [excerciseDetail, setExcerciseDetail] = useState({})
    const [edit, setEdit] = useState(false)
    const [fields1, setFields1] = useState({
        workout_name: "",
        activity_level: "",
        imageUrl: ""
    })

    const handleValidation1 = () => {
        let formIsValid = true;
        let newErrors = {};
        const { workout_name } = fields1
        if (/[^a-zA-Z\s]+/.test(workout_name) && workout_name.length >= 1) {
            formIsValid = false;
            newErrors = { ...newErrors, workout_name: ERR_MESSAGE.INVALID_TITLE };
        }
        setErrors(newErrors);
        return formIsValid;
    };

    const handleChange1 = (e) => {
        setErrors("")
        const name = e.target.name;
        const value = e.target.value;
        setFields1({
            ...fields1,
            [name]: value
        })
    }
    const handleEditOpen = (workoutName, activityLevel, imageUrl) => {
        setEdit(true)
        setFields1({
            ...fields1,
            workout_name: workoutName,
            activity_level: activityLevel,
            imageUrl: imageUrl
        })
    }
    const handleEditClose = () => {
        setEdit(false)
    }

    const handleOpen = (id) => {
        setOpen(true);
        getDetailApi(id);
    }

    const handleClose = () => setOpen(false);
    const handleDeleteOpen = () => {
        setOpenDelete(true);
    };

    const [fields, setFields] = useState({
        day: "",
        exercise: "",
        sets: "",
        reps: "",
        duration: "",
    })

    async function getApi() {
        setLoading(true)
        let result = await Services.getService("workout/all-exercise");
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseList1(result?.body?.data);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    useEffect(() => {
        getApi()
    }, [])

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setFields({
            ...fields,
            [name]: value
        })
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { day, exercise, sets, reps, duration } = fields
        if (!day) {
            formIsValid = false;
            errors["day"] = ERR_MESSAGE.EMPTY_DAY;
        }
        if (!exercise) {
            formIsValid = false;
            errors["exercise"] = ERR_MESSAGE.EMPTY_EXCERCISE;
        }
        if (!sets || sets.trim() === '') {
            formIsValid = false;
            errors["sets"] = ERR_MESSAGE.EMPTY_SETS;
        } else if (!/^[0-9]+$/.test(sets)) {
            formIsValid = false;
            errors["sets"] = ERR_MESSAGE.INVALID_SETS;
        }
        if (!reps || reps.trim() === '') {
            formIsValid = false;
            errors["reps"] = ERR_MESSAGE.EMPTY_REPS;
        }
        else if (!/^[0-9]+$/.test(reps)) {
            formIsValid = false;
            errors["reps"] = ERR_MESSAGE.INVALID_REPS;
        }

        if (!duration || duration.trim() === '') {
            formIsValid = false;
            errors["duration"] = ERR_MESSAGE.EMPTY_DURATION;

        } else if (!/^[:0-9]+$/.test(duration)) {
            formIsValid = false;
            errors["duration"] = ERR_MESSAGE.INVALID_DURATION;
        }


        setErrors(errors);
        return formIsValid;
    }

    const fileChange = (e) => {
        setErrors("")
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
            const formData = new FormData();
            formData.append("mediaFile", file);
            const imageURL = URL.createObjectURL(file);
            // setImageUrl(imageURL);
            // setUrlType("1")
            setFields1({
                ...fields1,
                imageUrl: imageURL
            })
            fileUpload(file);
        }

    };
    async function getDetailApi(id) {
        setLoading(true)
        let result = await Services.getService(`workout/exercise-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseDetail(result?.body);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    async function getWorkoutApi() {
        setLoading(true)
        let result = await Services.getService(`workout/workouts-details?id=${Id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setWorkoutDetail(result?.body);
            setExcerciseList(result?.body?.exercises)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    async function getUserApi() {
        setLoading(true)
        let result = await Services.getService(`workout/get-assigned-user?id=${Id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setUserList(result?.body?.data)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    useEffect(() => {
        getWorkoutApi()
        getUserApi()
    }, [])

    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
                setFields1({
                    ...fields1,
                    imageUrl: fileUrl
                })
                // setFileUrl(fileUrl)
            }
        }
        catch (error) {
        }
    };

    useEffect(() => {
        const videoElement = document.getElementById('videoElement');
        if (videoElement) {
            videoElement.src = videoUrl;
            videoElement.load();
        }
    }, [videoUrl]);

    async function postApi(e) {
        e.preventDefault()
        if (handleValidation()) {
            let data = JSON.stringify(
                {
                    "id": Id,
                    "exercise_id": fields.exercise,
                    "days": fields.day,
                    "sets": fields.sets,
                    "reps": fields.reps,
                    "duration": parseInt(fields.duration)
                });
            setLoading(true);

            const result = await Services.postService("workout/update-workout-exercise", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleDeleteClose()
                getWorkoutApi()
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    const handleDeleteClose = () => setOpenDelete(false);

    async function postEditWorkout(e) {
        e.preventDefault()
        if (handleValidation1()) {
            let data = JSON.stringify(
                {
                    "id": Id,
                    "workout_name": fields1.workout_name,
                    "activity_level": fields1.activity_level,
                    "image_url": fields1.imageUrl
                });
            setLoading(true);
            const result = await Services.postService("workout/update-workout", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleEditClose()
                getWorkoutApi()
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }
    const handleEdit = (id) => {
        navigate(ROUTES.CUSTOMER_PROFILE, { state: { id } })
    }

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Sunday'];

    return (
        <>
            <Helmet>
                <title>{TITLES.WORKOUT_DETAIL}</title>
            </Helmet>
            {loading &&
                <ReactLoader />
            }
            <div className='container-fluid my-4 pl-2'>
                <h5 className='mb-0 fw-600'>Workouts / Workout Detail</h5>
            </div>
            <section className="main-spacing mt-3 main-height">
                <div className="container-fluid ">
                    <div className="row m-0 responsiveGap">
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='workout-detail-wrapper bg-white p-3 pb-4 mb-3'>
                                <img src={workoutDetail?.image_url} className='w-100 workoutDetilImg' alt='' />
                                <div className='d-flex justify-content-between flex-wrap align-items-start my-3'>
                                    <h2>{workoutDetail?.workout_name}</h2>
                                    {
                                      userList?.length  === 0 &&    <button type="button" class=" next-btn" onClick={() => handleEditOpen(workoutDetail?.workout_name, workoutDetail?.activity_level, workoutDetail?.image_url)}>Edit</button>
                                    }
                                 
                                </div>
                                <ul>
                                    <li className='f16-size liColor fw-400 mb-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                            <path d="M4.31781 16.3754L2.26792 14.3255C1.8429 15.5944 3.04938 16.8002 4.31781 16.3754Z" fill="#767676" />
                                            <path d="M7.68035 16.1769L2.54211 11.0386C2.26109 10.7576 1.80547 10.7576 1.52445 11.0386C1.24344 11.3197 1.24344 11.7753 1.52445 12.0563L6.6627 17.1945C6.94372 17.4756 7.39934 17.4756 7.68035 17.1945C7.96137 16.9135 7.96137 16.4579 7.68035 16.1769Z" fill="#767676" />
                                            <path d="M10.0872 6.26813L5.96573 10.3896L3.7909 8.2148C3.43963 7.86353 2.87011 7.86353 2.51884 8.2148C2.16757 8.56607 2.16757 9.13559 2.51884 9.48686L9.23419 16.2022C9.58546 16.5535 10.155 16.5535 10.5062 16.2022C10.8575 15.8509 10.8575 15.2814 10.5062 14.9301L8.25511 12.679L12.3766 8.55751L14.6277 10.8086C14.979 11.1599 15.5485 11.1599 15.8998 10.8086C16.2511 10.4574 16.2511 9.88786 15.8998 9.53659L9.18446 2.82124C8.83319 2.46997 8.26367 2.46997 7.9124 2.82124C7.56113 3.17251 7.56113 3.74203 7.9124 4.0933L10.0872 6.26813Z" fill="#767676" />
                                            <path d="M14.0234 2.57169L16.0733 4.62158C16.4983 3.35265 15.2919 2.14683 14.0234 2.57169Z" fill="#767676" />
                                            <path d="M16.8913 6.96717L11.753 1.82893C11.472 1.54791 11.0164 1.54791 10.7354 1.82893C10.4544 2.10994 10.4544 2.56556 10.7354 2.84658L15.8736 7.98483C16.1547 8.26584 16.6103 8.26584 16.8913 7.98483C17.1723 7.70381 17.1723 7.24819 16.8913 6.96717Z" fill="#767676" />
                                        </svg>
                                        <span className='ms-2'>{workoutDetail?.no_of_exercise} exclusive {workoutDetail?.no_of_exercise == 1 ? "training" : "trainings"}</span>
                                    </li>
                                    <li className='f16-size liColor fw-400 '>
                                        <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1766_3963)">
                                                <path d="M10.211 0L7.57353 0C7.22478 0 6.94141 0.283373 6.94141 0.632123C6.94141 0.980874 7.22478 1.26425 7.57353 1.26425L10.211 1.26425C10.5598 1.26425 10.8431 0.980874 10.8431 0.632123C10.8431 0.283373 10.5598 0 10.211 0Z" fill="#767676" />
                                                <path d="M17.2525 4.16335L15.5305 2.44137C15.2908 2.20159 14.8984 2.20159 14.6369 2.44137C14.3971 2.68114 14.3971 3.07349 14.6369 3.33504L16.3371 5.03524C16.4679 5.16604 16.6204 5.20964 16.773 5.20964C16.9256 5.20964 17.1 5.14426 17.209 5.03524C17.4923 4.79547 17.4923 4.40312 17.2525 4.16335Z" fill="#767676" />
                                                <path d="M8.88287 2.63745C4.52338 2.63745 0.992187 6.16864 0.992187 10.5281C0.992187 14.8876 4.52338 18.4188 8.88287 18.4188C13.2424 18.4188 16.7735 14.8876 16.7735 10.5281C16.7735 6.16864 13.2424 2.63745 8.88287 2.63745ZM12.1525 11.3782H8.88287C8.53412 11.3782 8.25074 11.0948 8.25074 10.7461V6.64819C8.25074 6.29944 8.53412 6.01607 8.88287 6.01607C9.23162 6.01607 9.51499 6.29944 9.51499 6.64819L9.51499 10.1358L12.1525 10.1358C12.5012 10.1358 12.7846 10.4192 12.7846 10.7679C12.7846 11.0949 12.5012 11.3782 12.1525 11.3782Z" fill="#767676" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1766_3963">
                                                    <rect width="18.4188" height="18.4188" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <span className='ms-2'>{workoutDetail?.duration} min, {workoutDetail?.activity_level}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='workout-detail-wrapper bg-white p-3 pb-4 mt-4'>

                                <div className='d-flex justify-content-between flex-wrap align-items-start my-3'>
                                    <h4 className='f18 '>Exercises</h4>
                                    <button type="submit" class=" next-btn" onClick={handleDeleteOpen}>Add</button>
                                </div>
                                <div className='exercise-list-wrapper'>
                                    {
                                        excerciseList.length <= 0 ? <div className='text-center'>No exercises found</div> :
                                            <>
                                                {
                                                    excerciseList?.map((item) => (
                                                        <div key={item.id} className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className='d-flex  align-items-start gap-4'>
                                                                {item?.url?.includes('.mp4') ? (
                                                                    <video width="130" height="80" controls className='border10'>
                                                                        <source src={item.url} type="video/mp4" className='input-profile-video' />
                                                                    </video>
                                                                ) : (
                                                                    <img src={item.url} className="training-demo workoutDetailImage" alt=''  />
                                                                )}
                                                                <div>     <h4 className='f18 fw-600'>{item.exercise_name}</h4>
                                                                    <p className='f18-size mt-2 liColor'>{item.exercise_details.sets} Sets x {item.exercise_details.reps}  Reps</p>
                                                                </div>
                                                            </div>
                                                            <div style={{ cursor: "pointer" }}>
                                                                <img src={ARROWRIGHT} onClick={() => handleOpen(item.id)} />
                                                            </div>

                                                        </div>
                                                    ))
                                                }
                                            </>
                                       }
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='bg-white px-4 py-3 heightOverflow'>
                                {/* <div className='d-flex justify-content-between flex-wrap align-items-start mb-3'>
                                    <h2 className='fw-700'>Assigned</h2>
                                    <button type="submit" class=" next-btn">Add</button>
                                </div> */}
                                {
                                    userList?.length <= 0 ? <div className='no-assign'>No user assigned </div> : <>
                                        <div className='assign-wrapper-list'>
                                            {
                                                userList?.map((item) => (
                                                    <div className='d-flex justify-content-between align-items-center mb-3 border-bottom pb-3 ' key={item?.id}>
                                                        <div className='d-flex  align-items-start gap-4'>
                                                            <img src={item?.user?.profile_pic ? item?.user?.profile_pic : USER_PROFILE} className='profile-image' alt='' />
                                                            <div>     <h4 className='f18 fw-600'>{item?.user?.name}</h4>
                                                                <p className='f14-size mt-1 liColor'> {daysOfWeek[new Date(item?.assign_date).getDay()]} every week</p>
                                                            </div>
                                                        </div>
                                                        <img src={ARROWRIGHT} style={{ cursor: "pointer" }} onClick={() => handleEdit(item?.user_id)} alt='' />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt='' />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Excercise Detail</h2>
                    </div>
                    <div className='exercise-modal-body px-3'>
                        <h3 className='f18 fw-600 text-start text-uppercase my-2'>Images / Videos</h3>
                        {excerciseDetail?.url_type === "2" ? (
                            <video width="254" height="220" controls>
                                <source src={excerciseDetail?.url} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={excerciseDetail?.url} className='detailimg my-2' alt=''  />
                        )}
                        <div className='mt-2'>
                            <div className="mb-3 text-start">
                                <label className="form-label ">EXCERCISE NAME </label>
                                <input type="text" className="form-control common-input" placeholder="Enter here" value={excerciseDetail?.exercise_name} />
                            </div>
                        </div>

                    </div>

                    <div
                        className="mb-3 text-center position-relative submit-head"
                    >
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleDeleteClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt=''  />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Add Excercise</h2>
                    </div>
                    <div className='exercise-modal-body px-3'>

                        <div className="mb-3 text-start">
                            <label className="form-label text-start">Day</label>
                            <select
                                name="day"
                                value={fields.day}
                                onChange={handleChange}
                                className="form-select common-input"
                            >
                                <option value="" >Select</option>
                                <option value="1" >Day 1</option>
                                <option value="2" >Day 2</option>
                                <option value="3" >Day 3</option>
                                <option value="4" >Day 4</option>
                                <option value="5" >Day 5</option>

                                <option value="6" >Day 6</option>

                            </select>
                            {errors.day && <span className="err_msg">{errors.day}</span>}

                        </div>
                    </div>
                    <div className='exercise-modal-body px-3'>

                        <div className="mb-3 text-start">
                            <label className="form-label text-start">Exercise</label>
                            <select
                                name="exercise"
                                value={fields?.exercise}
                                onChange={handleChange}
                                className="form-select common-input"
                            >
                                <option value="" selected>Select</option>
                                {
                                    excerciseList1?.map((item) => {
                                        return (
                                            <>
                                                <option key={item?.id} value={item?.id}>{item?.exercise_name}</option>
                                            </>
                                        )

                                    })
                                }

                            </select>

                            {errors.exercise && <span className="err_msg">{errors.exercise}</span>}
                        </div>


                        <div className="mb-3 text-start">
                            <label className="form-label text-start">Sets</label>
                            <input
                                type="text"
                                name="sets"
                                value={fields?.sets}
                                onChange={handleChange}
                                className="form-control common-input"
                                placeholder="Enter here"
                            />
                            {errors.sets && <span className="err_msg">{errors.sets}</span>}
                        </div>

                        <div className="mb-3 text-start">
                            <label className="form-label text-start">Reps</label>
                            <input
                                type="text"
                                name="reps"
                                value={fields?.reps}
                                onChange={handleChange}
                                className="form-control common-input"
                                placeholder="Enter here"
                            />
                            {errors.reps && <span className="err_msg">{errors.reps}</span>}
                        </div>

                        <div className="mb-3 text-start">
                            <label className="form-label text-start">Duration(min)</label>
                            <input
                                type="text"
                                name="duration"
                                value={fields?.duration}
                                onChange={handleChange}
                                className="form-control common-input"
                                placeholder="Enter here"
                            />
                            {errors.duration && <span className="err_msg">{errors.duration}</span>}
                        </div>
                    </div>
                    <div
                        className="mb-3 text-center position-relative submit-head w-100 mt-4 px-3"
                    >
                        <button
                            onClick={postApi}
                            type="submit"
                            className="btn delete-btn w-100"
                        >
                            Submit{" "}
                        </button>

                    </div>

                </Box>
            </Modal >

            <Modal
                open={edit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleEditClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt='' />
                    </div>
                    <div className='exercise-modal-body px-3'>
                        <h3 className='f18-size fw-600 text-uppercase text-start'>Image</h3>

                        <div className="position-relative mb-3">
                            <div className="profile-pic-change ">
                                <div className="profile-upper-pic execise-img">
                                    {!fields1.imageUrl && <img src={ADDBRANCH} className='img-fluid' alt="Placeholder"  />}
                                    {fields1.imageUrl && <img src={fields1.imageUrl == "" ? ADDBRANCH : fields1.imageUrl} className='img-fluid' alt='' />}
                                </div>
                            </div>
                            <input
                                type="file"
                                onChange={fileChange}
                                className="input-profile"
                                id="image"
                                accept=".jpg, .jpeg, .png, .mp4, .mov, .avi"
                            />
                        </div>
                        <div className='mt-2'>
                            <div className="mb-3 text-start">
                                <label className="form-label ">Workout Name</label>
                                <input type="text" name="workout_name" value={fields1.workout_name} onChange={handleChange1} className="form-control common-input" placeholder="Enter here" />
                                {errors.workout_name && <span className="err_msg">{errors.workout_name}</span>}
                            </div>
                        </div>
                        <div className='mt-2'>
                            <div className="mb-3 text-start">
                                <label className="form-label ">Workout Activity Level</label>
                                <select name="activity_level" value={fields1.activity_level} onChange={handleChange1}
                                    className="form-select common-input"
                                >                                                                    <option value="">Select</option>
                                    <option value="Rokie">Rokie</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advance ">Advance</option>
                                    <option value="True Beast">True Beast</option>

                                </select>

                            </div>
                        </div>
                        <div
                            className="mb-3 text-center position-relative submit-head w-100 mt-4"
                        >
                            <button
                                onClick={postEditWorkout}
                                type="submit"
                                className="btn delete-btn w-100"
                            >
                                Submit{" "}
                            </button>

                        </div>
                    </div>
                </Box>
            </Modal >
            <ToastContainer />
        </>
    )
}




export default WorkoutDetail